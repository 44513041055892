import "./DownloadExtension.css";
import { BsFillPlayFill } from "react-icons/bs"
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function DownloadExtension(): JSX.Element {
    const { t } = useTranslation();
    const navigate = useNavigate()


    return (
        <div className="DownloadExtension">
            <h1>{t('download_extension_welcome_text')}</h1> 
            <p>{t("download_extension_description_text")}</p>
            <div className="Download_Video_Container">
                <button className="btn_video" onClick={() => navigate("/video")}>{t("download_extension_video_button_text")} <BsFillPlayFill/></button>
                <a target="_blank" href="https://chrome.google.com/webstore/detail/english-to-hebrew-transla/pdchgmgjkeopcekfahjaikiclljlcpjc">
                    <button className="btn_extension" >{t("download_extension_action_button_text")}</button>
                </a>
            </div>
        </div>
    );
}

export default DownloadExtension;
