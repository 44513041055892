import "./EditArticleModal.css";
import * as React from 'react';
import Button from '@mui/joy/Button';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import Textarea from '@mui/joy/Textarea';
import { ServicesManagerDashboard } from "../../../../../../Services/ServicesManagerDashboard";
import { useState } from "react";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import IsraelLogo from "../../../../Header/Israel.png";
import USALogo from "../../../../Header/USA.png";
import arabLogo from "../../../../Header/arab.png";
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from "react-i18next";

function EditArticleModal( { editArticleModalOpen, setEditArticleModalOpen, articleValue }: { editArticleModalOpen: boolean, setEditArticleModalOpen: (e: boolean) => void, articleValue: any}): JSX.Element {
  const { t, i18n } = useTranslation();
  const [languageEdit, setLanguageEdit] = useState<string>(i18n.language)


  async function NewArticleValue(newArticle: any) {
    ServicesManagerDashboard.editArticle(articleValue.id, newArticle)
  }

  // const [language, setLanguage] = React.useState("he");
  const languages = [
    { label: 'עברית', value: "he", image: IsraelLogo },
    { label: 'English', value: "en", image: USALogo },
    { label: 'عربي', value: "ar", image: arabLogo },
  ]

  const changeLanguageHandler = (event: SelectChangeEvent ) => {
    // setLanguage(event.target.value as string);
      setLanguageEdit(event.target.value)
};

    return (
        <div className="EditArticleModal">
<React.Fragment>


  
      <Modal open={editArticleModalOpen} onClose={() => setEditArticleModalOpen(false)}>
        
                             
        <ModalDialog
          aria-labelledby="basic-modal-dialog-title"
          aria-describedby="basic-modal-dialog-description"
          sx={{ maxWidth: 1500, width: "70%", direction: `${i18n.language !== "en" ? "rtl" : "ltr"}`}}
        >

          <Typography sx={{display: "flex", justifyContent: "space-between"}} id="basic-modal-dialog-title" level="h2">
        {t("manager_dashboard_blog_article_edit")}
          <Box >
                                                <FormControl sx={{direction: "ltr"}} >
                                                    
                                                    <Select
                                                    id="select-language"
                                                    value={languageEdit}
                                                    onChange={changeLanguageHandler}
                                                    variant="outlined"
                                                    sx={{
                                                      display: 'flex',
                                                      alignItems: 'center',
                                                      justifyContent: 'flex-end',
                                                      '& .MuiOutlinedInput-notchedOutline': {
                                                          borderColor: 'transparent', 
                                                        },
                                                          '&:hover': {
                                                            '& .MuiOutlinedInput-notchedOutline': {
                                                              borderColor: 'transparent',
                                                            },
                                                          },
                                                    height: 29,
                                                    }}
                                                    >

                                                    {languages.map((language) => (
                                                        <MenuItem sx={{display: "flex", alignItems: "center", justifyContent:"end"}} key={language.value} value={language.value}>
                                                        {language.label}
                                                        <img src={language.image} alt={language.label} style={{ marginLeft: '14px' }} />
                                                        </MenuItem>
                                                    ))}
                                                    </Select>
                                                </FormControl>
                                                </Box>

          </Typography>
          <Typography id="remember_edit_languages">
          *{t("manager_dashboard_blog_article_edit_remember")}
          </Typography>
          <form
            onSubmit={(event:any) => {
              event.preventDefault();
              
              let newArticle = {
                titleHE: event.target[0].value,
                descriptionHE: event.target[1].value,
                bodyHE: event.target[3].value,
                titleEN: event.target[5].value,
                descriptionEN: event.target[6].value,
                bodyEN: event.target[8].value,
                titleAR: event.target[10].value,
                descriptionAR: event.target[11].value,
                bodyAR: event.target[13].value,
              }
              
              NewArticleValue(newArticle)
              setEditArticleModalOpen(false);
            }}
            >
            <Stack spacing={2}>
              <div style={{display: `${languageEdit == "he" ? "block" : "none"}`}}>
              <FormControl >
                <FormLabel>
                  {t("manager_dashboard_blog_article_edit_title")}
                </FormLabel>
                <Input autoFocus required defaultValue={articleValue?.titleHE}/>
                {/* <div  contenteditable="true"></div> */}
              </FormControl>
              <FormControl>
                <FormLabel>
                  {t("manager_dashboard_blog_article_edit_descrtiption")}
                </FormLabel>
                <Textarea  minRows={3} maxRows={3} defaultValue={articleValue?.descriptionHE} />
              </FormControl>
              <FormControl>
                <FormLabel>
                {t("manager_dashboard_blog_article_edit_body")}
                </FormLabel>
                <Textarea maxRows={5} minRows={5} defaultValue={articleValue?.bodyHE }/>
              </FormControl>
              </div>
              <div style={{display: `${languageEdit == "en" ? "block" : "none"}`}}>
              <FormControl >
                <FormLabel>{t("manager_dashboard_blog_article_edit_title")}</FormLabel>
                <Input autoFocus required defaultValue={articleValue?.titleEN} sx={{direction:"ltr"}}/>
                {/* <div  contenteditable="true"></div> */}
              </FormControl>
              <FormControl>
                <FormLabel>{t("manager_dashboard_blog_article_edit_descrtiption")}</FormLabel>
                <Textarea  minRows={3} maxRows={3} defaultValue={articleValue?.descriptionEN}  sx={{direction:"ltr"}}/>
              </FormControl>
              <FormControl>
                <FormLabel>{t("manager_dashboard_blog_article_edit_body")}</FormLabel>
                <Textarea maxRows={5} minRows={5} defaultValue={articleValue?.bodyEN }  sx={{direction:"ltr"}}/>
              </FormControl>
              </div>
              <div style={{display: `${languageEdit == "ar" ? "block" : "none"}`}}>
              <FormControl >
                <FormLabel>{t("manager_dashboard_blog_article_edit_title")}</FormLabel>
                <Input autoFocus required defaultValue={articleValue?.titleAR}/>
                {/* <div  contenteditable="true"></div> */}
              </FormControl>
              <FormControl>
                <FormLabel>{t("manager_dashboard_blog_article_edit_descrtiption")}</FormLabel>
                <Textarea  minRows={3} maxRows={3} defaultValue={articleValue?.descriptionAR} />
              </FormControl>
              <FormControl>
                <FormLabel>{t("manager_dashboard_blog_article_edit_body")}</FormLabel>
                <Textarea maxRows={5} minRows={5} defaultValue={articleValue?.bodyAR }/>
              </FormControl>
              </div>
              <Button type="submit">
               {t("manager_dashboard_blog_article_edit_button_accept")}
              </Button>
            </Stack>
          </form>
        </ModalDialog>
      </Modal>
    </React.Fragment>
        </div>
    );
}

export default EditArticleModal;

