import { useTranslation } from "react-i18next";
import "./AdvantagesSection.css";
import advantage1image from "./advantage1image.jpg";
import advantage2image from "./advantage2image.jpg";
import advantage3image from "./advantage3image.jpg";
import advantage4image from "./advantage4image.jpg";
import { useSelector } from "react-redux";

function AdvantagesSection(): JSX.Element {
    const { t } = useTranslation();
    const siteImages = useSelector((state: any) => state.siteImagesSlice);


    return (
        <div className="AdvantagesSection">
			      <div className="advantages_container">
                    <h3 className="advantages_title">{t("blog_advantages_title")}</h3>
                    <div className="advantages_boxes">
                        <div className="advantage_box">
                            <img src={siteImages.advantage1image} alt="" />
                            
                        </div>
                        <div className="advantage_box">
                            <img src={siteImages.advantage2image} alt="" />
                            
                        </div>
                        <div className="advantage_box">
                            <img src={siteImages.advantage3image} alt="" />
                            
                        </div>
                        <div className="advantage_box">
                            <img src={siteImages.advantage4image} alt="" />
                            
                        </div>
                    </div>
                </div>
        </div>
    );
}


export default AdvantagesSection;
