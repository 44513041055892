import * as React from 'react';
import Button from '@mui/joy/Button';
import Divider from '@mui/joy/Divider';
// import DialogTitle from '@mui/joy/DialogTitle';
// import DialogContent from '@mui/joy/DialogContent';
// import DialogActions from '@mui/joy/DialogActions';
import { GoAlertFill } from 'react-icons/go'
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import { useTranslation } from 'react-i18next';
// import DeleteForever from '@mui/icons-material/DeleteForever';
// import WarningRoundedIcon from '@mui/icons-material/WarningRounded';


// todo set this in Chat component  const [openDeleteMessagesModal, setOpenDeleteMessagesModal] = React.useState<boolean>(false);
  // 
export default function ChangeTeacherDeleteMessagesModal({openDeleteMessagesModal, setOpenDeleteMessagesModal, ChangeTeacher}: {openDeleteMessagesModal: boolean, setOpenDeleteMessagesModal: (e: boolean) => void, ChangeTeacher: () => void} ) {
  const { t } = useTranslation();

  function handleClickedDelete(){
    setOpenDeleteMessagesModal(false)
    ChangeTeacher()
  }
  
  return (
    <React.Fragment>
      <Modal open={openDeleteMessagesModal} onClose={() => setOpenDeleteMessagesModal(false)}>
        <ModalDialog variant="outlined" role="alertdialog">
          <title style={{display: "flex", gap: "20px", justifyContent: "center", alignItems: "center"}}>
            <GoAlertFill/>
            {t("chat_modal_title")}
          </title>
          <Divider />
          <div style={{margin: "0 auto 20px auto"}}>
            {t("chat_modal_desc_teacher")}
          </div>
          <div style={{display: "flex", gap: "20px", justifyContent: "center", alignItems: "center"}}>
            <Button variant="solid" color="danger" onClick={() => handleClickedDelete()}>
            {t("chat_modal_delete")}
            </Button>
            <Button variant="plain" color="neutral" onClick={() => setOpenDeleteMessagesModal(false)}>
            {t("chat_modal_cancel")}
            </Button>
          </div>
        </ModalDialog>
      </Modal>
    </React.Fragment>
  );
}