import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { siteImagesModel } from '../Models/siteModel';

const storedSiteImages = window.localStorage.getItem("siteImages");

const siteImages = storedSiteImages && storedSiteImages !== "[object Object]" && storedSiteImages !== "undefined"
  ? JSON.parse(storedSiteImages)
  : {};
  const initialState: siteImagesModel = siteImages;


const siteImagesSlice = createSlice({
  name: 'siteImages',
  initialState,
  reducers: {
    setSiteImages: (state, action: PayloadAction<siteImagesModel>) => {
      // Directly modifying the state object
      Object.assign(state, action.payload);
      
      // Save the new state in local storage
      window.localStorage.setItem("siteImages", JSON.stringify(action.payload));
    },
  }
});

export const { setSiteImages } = siteImagesSlice.actions;

export default siteImagesSlice.reducer;