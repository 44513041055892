import { config } from "../helpers/config";


class ServicesFunctions {
//  BASE_URL = "http://localhost:4000";
//  BASE_URL = "https://services.e-shop.co.il/dashboard";

 // request to get topMessage json 
 async getTopMessage() {
    try {
      const response = await fetch(`${config.BASE_URL}/topMessage`, {
        mode: "cors",
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Origin": "*",
        }
      });
      const data = await response.json();
      return data;
    } catch (e) {
      console.log(e);
      return {};
    }
  }
  
 // request to get WebInfo json 
 async getWebInfo() {
    try {
      const response = await fetch(`${config.BASE_URL}/webInfo`, {
        mode: "cors",
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Origin": "*",
          authorization: "" + window.localStorage.getItem("ClickLearnLogged"),
        }
      });
      const data = await response.json();
      return data;
    } catch (e) {
      console.log(e);
      return {};
    }
  }
  

  
  
 // request to get User Site Data json 
 async getSiteStatistic() {
    try {
      const response = await fetch(`${config.BASE_URL}/siteStatistic`, {
        mode: "cors",
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Origin": "*",
          authorization: "" + window.localStorage.getItem("ClickLearnLogged"),
        }
      });
      const data = await response.json();
      return data[0];
    } catch (e) {
      console.log(e);
      return {};
    }
  }
  
 // request to get User Site soruce json 
 async getSiteReferrers() {
    try {
      const response = await fetch(`${config.BASE_URL}/siteReferrers`, {
        mode: "cors",
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Origin": "*",
          authorization: "" + window.localStorage.getItem("ClickLearnLogged"),
        }
      });
      const data = await response.json();
      return data[0];
    } catch (e) {
      console.log(e);
      return {};
    }
  }


  
 // request to get Site Source json 
 async getSiteSource() {
    try {
      const response = await fetch(`${config.BASE_URL}/siteSource`, {
        mode: "cors",
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Origin": "*",
        }
      });
      const data = await response.json();
      return data;
    } catch (e) {
      console.log(e);
      return {};
    }
  }
  

  
 // request to get User Integrations json 
 async getImages() {
    try {
      const response = await fetch(`${config.BASE_URL}/userIntegrations`, {
        mode: "cors",
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Origin": "*",
        }
      });
      const data = await response.json();
      return data;
    } catch (e) {
      console.log(e);
      return {};
    }
  }
  
 // request to get Site Customers By Device json 
 async getColorsSection() {
    try {
      const response = await fetch(`${config.BASE_URL}/ColorsSection`, {
        mode: "cors",
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Origin": "*",
        }
      });
      const data = await response.json();
      return data;
    } catch (e) {
      console.log(e);
      return {};
    }
  }
  
 // request to get Top Sale Products json 
 async getTopWords() {
    try {
      const response = await fetch(`${config.BASE_URL}/getTopWords`, {
        mode: "cors",
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Origin": "*",
          authorization: "" + window.localStorage.getItem("ClickLearnLogged"),
        }
      });
      const data = await response.json();
      return data;
    } catch (e) {
      console.log(e);
      return {};
    }
  }
  
 // request to get Customers Types to CustomersChart json 
 async getCustomerTypes() {
    try {
      const response = await fetch(`${config.BASE_URL}/customerTypes`, {
        mode: "cors",
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Origin": "*",
        }
      });
      const data = await response.json();
      return data;
    } catch (e) {
      console.log(e);
      return {};
    }
  }


 async changeMainColorSite(color) {
    try {
      const response = await fetch(`${config.BASE_URL}/changeMainColorSite`, {
        mode: "cors",
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Origin": "*",
          authorization: "" + window.localStorage.getItem("ClickLearnLogged"),
        },
        body: JSON.stringify({ color: color }), // Assuming color is a string like "#c85151"

      });
      // const data = await response.json();
      // return data;
      return
    } catch (e) {
      console.log(e);
      return {};
    }
  }


  async uploadImage(key, event) {
    const image = event.target.files[0];
    const type = image.type.split('/')[1]; 
    
    if(!['jpg', 'jpeg', 'png'].includes(type)) {
      return 400;
    }
  
    const formData = new FormData();
    formData.append('key', key);
    formData.append('image', image);
  
    try {
      const response = await fetch(`${config.BASE_URL}/uploadImage`, {
        method: 'POST',
        headers: {
          'Access-Control-Origin': '*',
          authorization: "" + window.localStorage.getItem("ClickLearnLogged"),
        },
        body: formData
      });
  
      if (response.ok) {
        const data = await response.json();
        return data;
      } else {
        console.error(`Server returned ${response.status}: ${response.statusText}`);
        return null;
      }
  
    } catch (e) {
      console.error(e);
      return e;
    }
  }
  

  
  async getAllArticles() {
    try {
      const response = await fetch(`${config.BASE_URL}/getAllArticles`, {
        mode: "cors",
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Origin": "*",
        },
  
      });
      const data = await response.json();
      return data;
    } catch (e) {
      console.log(e);
      return {};
    }
  }
  
  async getArticle(id) {
    try {
      const response = await fetch(`${config.BASE_URL}/getArticle/${id}`, {
        mode: "cors",
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Origin": "*",
        },
  
      });
      const data = await response.json();
      return data[0];
      // return
    } catch (e) {
      console.log(e);
      return {};
    }
  }
  
  async editArticle(id, newArticle) {
   try {
     const response = await fetch(`${config.BASE_URL}/editArticle/${id}`, {
       mode: "cors",
       method: "PUT",
       headers: {
         "Content-Type": "application/json",
         "Access-Control-Origin": "*",
         authorization: "" + window.localStorage.getItem("ClickLearnLogged"),
       },
       body: JSON.stringify({ newArticle: newArticle }), // Assuming color is a string like "#c85151"
 
     });
     return
   } catch (e) {
     console.log(e);
     return {};
   }
 }


 async getAllPodcasts() {
  try {
    const response = await fetch(`${config.BASE_URL}/getAllPodcasts`, {
      mode: "cors",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Origin": "*",
      },

    });
    const data = await response.json();
    return data;
  } catch (e) {
    console.log(e);
    return {};
  }
}


async getPodcast(id) {
  try {
    const response = await fetch(`${config.BASE_URL}/getPodcast/${id}`, {
      mode: "cors",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Origin": "*",
        authorization: "" + window.localStorage.getItem("ClickLearnLogged"),
      },

    });
    const data = await response.json();
    return data[0];
    // return
  } catch (e) {
    console.log(e);
    return {};
  }
}
   

async editPodcast(id, newPodcast) {
  try {
    const response = await fetch(`${config.BASE_URL}/editPodcast/${id}`, {
      mode: "cors",
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Origin": "*",
        authorization: "" + window.localStorage.getItem("ClickLearnLogged"),
      },
      body: JSON.stringify({ newPodcast: newPodcast }), // Assuming color is a string like "#c85151"

    });
    // const data = await response.json();
    // return data;
    return
  } catch (e) {
    console.log(e);
    return {};
  }
}

async getSubscribers() {
  try {
    const response = await fetch(`${config.BASE_URL}/getSubscribers`, {
      mode: "cors",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Origin": "*",
        authorization: "" + window.localStorage.getItem("ClickLearnLogged"),
      },
    });
    const data = await response.json();
    return data;
  } catch (e) {
    console.log(e);
    return {};
  }
}

async deleteSubscriber(id) {
  try {
    const response = await fetch(`${config.BASE_URL}/deleteSubscriber/${id}`, {
      mode: "cors",
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Origin": "*",
        authorization: "" + window.localStorage.getItem("ClickLearnLogged"),
      },
    });
    const data = await response.json();
    return data;
  } catch (e) {
    console.log(e);
    return {};
  }
}

async seenSubscriber(id) {
  try {
    const response = await fetch(`${config.BASE_URL}/seenSubscriber/${id}`, {
      mode: "cors",
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Origin": "*",
        authorization: "" + window.localStorage.getItem("ClickLearnLogged"),
      },
    });
    const data = await response.json();
    return data;
  } catch (e) {
    console.log(e);
    return {};
  }
}

async unSeenSubscriber(id) {
  try {
    const response = await fetch(`${config.BASE_URL}/unSeenSubscriber/${id}`, {
      mode: "cors",
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Origin": "*",
        authorization: "" + window.localStorage.getItem("ClickLearnLogged"),
      },
    });
    const data = await response.json();
    return data;
  } catch (e) {
    console.log(e);
    return {};
  }
}
async getExtenstionData() {
  try {
    const response = await fetch(`${config.BASE_URL}/extensionData`, {
      mode: "cors",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Origin": "*",
        authorization: "" + window.localStorage.getItem("ClickLearnLogged"),
      },
    });
    if(response.ok) {
      const data = await response.json();
      return data;
    }
  } catch (e) {
    console.log(e);
    return {};
  }
}



}

export const ServicesManagerDashboard = new ServicesFunctions();
