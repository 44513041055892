import "./ExtenstionData.css";
import { useEffect, useState } from "react";
import { ServicesManagerDashboard } from "../../../../../Services/ServicesManagerDashboard";
import { useTranslation } from "react-i18next";
import { FaRegStar, FaExchangeAlt } from "react-icons/fa";
import { FiDownload } from "react-icons/fi";

function ExtenstionData({ extentionInfo } : {extentionInfo: any}): JSX.Element {
  const { t } = useTranslation();

  return (
    <div className={`rc_ExtenstionData col-xl-12 col-sm-6 col-lg-5 p-4 col-12 dashboard_box ${extentionInfo ? "" : "loading_container"}`}>
  {/* <div className="col-12 box p-3"> */}

      <div className="pb-2">
        <h4 className="rc_component_title">{t("manager_dashboard_extension")}</h4>
      </div>
      <div className="d-flex justify-content-between rc_info_line">
        <div className="d-flex align-items-center mb-3 gap-2">
          <FiDownload color="var(--box-info-p-color)"/>
          <p>{t("manager_dashboard_extension_downloads")}</p>
        </div>
        <span>{extentionInfo?.downloads ?? <div className="loader"></div>}</span>
      </div>
      <div className="d-flex justify-content-between rc_info_line">
        <div className="d-flex align-items-center mb-3 gap-2 ">
          <FaRegStar color="var(--box-info-p-color)"/>
          <p>{t("manager_dashboard_extension_reviews")}</p>
        </div>
        <span>{extentionInfo?.reviews ?? <div className="loader"></div>}</span>
      </div>
      <div className="d-flex justify-content-between rc_info_line">
        <div className="d-flex align-items-center  gap-2 ">
          <FaExchangeAlt color="var(--box-info-p-color)"/>
          <p>{t("manager_dashboard_extension_amount_translated_words")}</p>
        </div>
        <span>{extentionInfo?.totalWordsTranslated ?? <div className="loader"></div>}</span>
      </div>
    {/* </div> */}
    </div>
  );
}

export default ExtenstionData;
