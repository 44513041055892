import { useState, useEffect} from "react";
import "./FirstTimeStep1.css";
import { t } from "i18next";
import { useTranslation } from "react-i18next";

function FirstTimeStep1({ setStep }: { setStep  : (e: number) => void}): JSX.Element {
  const [welcome, setWelcome] = useState(false);
  const [topWelcome, setTopWelcome] = useState(false);
  const [start, setStart] = useState(false);
  const [stepForward, setStepForward] = useState(false);
  const { t } = useTranslation();
useEffect(() => {
  window.scrollTo({
    top: 0,
    behavior: "smooth"
});
   const sound = setTimeout(() => {
    return () => {
        clearTimeout(sound);
      };
}, 1000);

}, [])
  setTimeout(() => {
    setWelcome(true);
  }, 3000);

  setTimeout(() => {
    setTopWelcome(true);
  }, 4000);

  setTimeout(() => {
    setStart(true);
  }, 4500);

  function StepForward() {
    setStepForward(true)

    setTimeout(() => {
        setStep(2)
    }, 1500);
  }



  return (
    <div className={`FirstTimeStep1 ${stepForward ? "fade_out_all" : ""}`}>
      {/* <div className="loader>' */}
      <div className={`loader ${topWelcome ? "up" : ""}`}>
        <div className={welcome ? "typing-effect" : "none"}>
          <h1>{t("chat_welcome_title")}</h1>
        </div>
      </div>

      <button
        className={`btn_start ${start ? "reveal" : "none_opacity"}`}
        onClick={StepForward}
      >
        {t("chat_lets_start")}
      </button>
    </div>
  );
}

export default FirstTimeStep1;
