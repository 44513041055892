import { useEffect, useState } from "react";
import "./FirstTimeStep4.css";
import { useDispatch, useSelector } from "react-redux";
import jwtDecode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { RegisterFromChat } from "../../../../../../App/authSlice";
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';
import { toastsFunctions } from "../../../../../../Services/ToastFunctions";
import { useTranslation } from "react-i18next";
import { servicesFunctions } from "../../../../../../Services/ServicesFunctions";

function FirstTimeStep4({ setStep, language, choseMaleTeacher, choseFemaleTeacher, userInfo, setUserInfo }: { setStep  : (e: number) => void, language: string, choseMaleTeacher: boolean, choseFemaleTeacher: boolean, userInfo: any, setUserInfo: (e: any) => void }): JSX.Element {
const [stepForward, setStepForward] = useState(false);
const dispatch = useDispatch();
const { t } = useTranslation();
const isLogin = useSelector((state: any) => state.authSlice);
const [reavel, setReveal] = useState(false)

useEffect(() => {
  console.log(isLogin);
  if(isLogin){
    if(userInfo.completedChatSettings == "true") {
      servicesFunctions.getUserInfo().then((res: any) => {
        setUserInfo(res)
      }).then(() => {
        StepForward()
      })
    } else {
        setReveal(true)
      let teacherLanguage = language
      let teacherGender = choseMaleTeacher ? "male" : "female";
      
      servicesFunctions.setUserInfo(teacherGender, teacherLanguage).then(() => {
        servicesFunctions.getUserInfo().then((res: any) => {
          setUserInfo(res)
        }).then(() => {
          StepForward()
        })
      })
    }

  } 
}, [])

    function StepForward() {
        setStepForward(true)
    
        setTimeout(() => {
            setStep(5)
        }, 1500);
      }


      function loginGoogle(credentialResponse: any) {
        let teacherGender = choseMaleTeacher ? "male" : "female";

        // const details: any = jwtDecode(credentialResponse.credential, language, teacherGender)
        const details = {
          credential: credentialResponse.credential,
          teacherLanguage: language, 
          teacherGender: teacherGender
      };
            try {
                dispatch(RegisterFromChat(details))
                setTimeout(() => {
                  window.location.reload();
                }, 500);
              
            } catch (e : any) {
                console.log(e);
                let failed_login_text = `${t("toast_functions_login_error")}`
                toastsFunctions.toastError(failed_login_text);
            }
        }
    return (
      <div
        className={`FirstTimeStep4 ${reavel ? "reveal" : ""} ${stepForward ? "fade_out_all" : ""} `}
      >
        {reavel ? 
        <h1>Register for FREE</h1>
        :
        <></>
        }
        {!isLogin ? (
          <GoogleOAuthProvider clientId="5407130036-dmu0ef4jmaslij5smo0d0lt31ug63jkv.apps.googleusercontent.com">
            <GoogleLogin
              onSuccess={(credentialResponse) => {
                loginGoogle(credentialResponse);
              }}
              onError={() => {
                console.log("Login Failed");
              }}
            />
          </GoogleOAuthProvider>
        ) : (
          <></>
        )}
      </div>
    );
}

export default FirstTimeStep4;
