import { useSelector } from "react-redux";
import "./Chat.css";
import { BsFillSendFill,BsEraserFill, BsFillMicFill, BsFillPlayFill,BsFillStopFill  } from "react-icons/bs";
import { RiDeleteBin6Line } from "react-icons/ri";
import { AiOutlinePause } from "react-icons/ai";
import { useEffect, useRef, useState } from "react";
import { servicesFunctions } from "../../../../../Services/ServicesFunctions";
import { ChatModel } from "../../../../../Models/chatModel";
import Avatar from "@mui/material/Avatar";
import { toastsFunctions } from "../../../../../Services/ToastFunctions";
import { useTranslation } from "react-i18next";
import FirstTimeStep1 from "./FirstTimeStep1/FirstTimeStep1";
import FirstTimeStep2 from "./FirstTimeStep2/FirstTimeStep2";
import FirstTimeStep3 from "./FirstTimeStep3/FirstTimeStep3";
import FirstTimeStep4 from "./FirstTimeStep4/FirstTimeStep4";
import AlertDialogModal from "./ChangeLanguageDeleteMessagesModal";
import { changeLanguage } from "i18next";
import ChangeLanguageDeleteMessagesModal from "./ChangeLanguageDeleteMessagesModal";
import ChangeTeacherDeleteMessagesModal from "./ChangeTeacherDeleteMessagesModal";

// const AiirstMessage: ChatModel = {
//   id: 10000000,
//   message: "Hello! You can ask me anything...",
//   timestamp: "",
//   role: 0,
// };

function Chat(): JSX.Element {
    const isLogin = useSelector((state: any) => state.authSlice);
    const [newMessage, setNewMessage] = useState<string>("");
    const [messages, setMessages] = useState<ChatModel[]>([])
    const [refresh, setRefresh] = useState<boolean>(true)
    const chatContainerRef = useRef<null | HTMLDivElement>(null);
    const [isAITyping, setIsAITyping] = useState<boolean>(false)
    const { t, i18n } = useTranslation();
    const siteImages = useSelector((state: any) => state.siteImagesSlice);

    const [language, setLanguage] = useState("english");
    const [autoPlay, setAutoPlay] = useState(true);
    const [choseMaleTeacher, setChoseMaleTeacher] = useState<boolean>(false)
    const [choseFemaleTeacher, setChoseFemaleTeacher] = useState<boolean>(false)
    const [firstHiddenMessage, setFirstHiddenMessage] = useState<string>('');
    const [dotsLoader, setDotsLoader] = useState<boolean>(true)
 
    useEffect(() => {
      setTimeout(() => {
        setDotsLoader(false)
      }, 1000);
    }, [])

const [step, setStep] = useState(1)
const [userInfo, setUserInfo] = useState<any>();
const [lastClicked, setLastClicked] = useState(0);
const [currentAudio, setCurrentAudio] = useState<any>(null);
const [deleteMessagesBtn, setDeleteMessagesBtn] = useState<boolean>(false)
const [openDeleteMessagesModal, setOpenDeleteMessagesModal] = useState<boolean>(false);
const [openLanguageDeleteMessagesModal, setLanguageOpenDeleteMessagesModal] = useState<boolean>(false);
    function capitalizeFirstLetterAndTakeOnlyFirstName(string: string) {
      let firstName = string.split(' ')[0];
      return firstName.charAt(0).toUpperCase() + firstName.slice(1);
  }
  

  useEffect(() => {

    if(!isLogin) return;
    if(!userInfo?.teacherLanguage) return
    const message = 
    userInfo?.teacherLanguage === "hebrew" 
      ? `היי קוראים לי ${capitalizeFirstLetterAndTakeOnlyFirstName(isLogin?.name)}`
      : userInfo?.teacherLanguage === "english"
      ? `Hey my name is ${capitalizeFirstLetterAndTakeOnlyFirstName(isLogin?.name)}`
      : userInfo?.teacherLanguage === "arabic"
      ? `مرحبًا، اسمي هو ${capitalizeFirstLetterAndTakeOnlyFirstName(isLogin?.name)}`
      : `Hey my name is ${capitalizeFirstLetterAndTakeOnlyFirstName(isLogin?.name)}`;
  
      setFirstHiddenMessage(message)
  }, [userInfo?.teacherLanguage])
    useEffect(() => {
      if(!isLogin){
        return
      }

      
      servicesFunctions.getConversationChat().then((res) => {
        if(step === 4){
          deleteMessages()
        }
        // if (res.length >= 1) {
          setMessages(res);
          if(res.length == 0) {
            setTimeout(() => {
              const AITypingMessage: ChatModel = {
                  id: 10000000,
                  message: t("chat_typing"),
                  timestamp: "",
                  role: 0,
                };
                let updatedMessages2: ChatModel[] = [];
                updatedMessages2.push(AITypingMessage);
                setMessages(updatedMessages2);
            }, 300);


            if(!language) return

            servicesFunctions.getUserInfo().then((res) => {
              if(!res) return
              
            const message = 
            res?.teacherLanguage === "hebrew" 
              ? `היי קוראים לי ${capitalizeFirstLetterAndTakeOnlyFirstName(isLogin?.name)}`
              : res?.teacherLanguage === "english"
              ? `Hey my name is ${capitalizeFirstLetterAndTakeOnlyFirstName(isLogin?.name)}`
              : res?.teacherLanguage === "arabic"
              ? `مرحبًا، اسمي هو ${capitalizeFirstLetterAndTakeOnlyFirstName(isLogin?.name)}`
              : `Hey my name is ${capitalizeFirstLetterAndTakeOnlyFirstName(isLogin?.name)}`;
          
              setFirstHiddenMessage(message)

            servicesFunctions.sendChatMessageToChatGPT(message, capitalizeFirstLetterAndTakeOnlyFirstName(isLogin?.name), "text", "" ).then(() => {
              setRefresh(!refresh)
              setDeleteMessagesBtn(false)
          });
          
        })
        }
        // }
      });
    }, [refresh, isLogin, step]);
    

    useEffect(() => {
        scrollToBottom();
    }, [messages])
    
    function scrollToBottom() {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    }
    

    
    const [isRecording, setIsRecording] = useState<boolean>(false);
    const [audioURL, setAudioURL] = useState<string>("");
    const isForceStopped = useRef(false);

   async function sendNewMessage(){ 
      if(!newMessage && !audioURL) return;
      
      if(isAITyping){
          let wait_to_response_text = `${t("chat_wait_to_response_please")}`
          toastsFunctions.toastInfo(wait_to_response_text);
          return;
      };
      
      setIsAITyping(true);
  
      let newChatModel: ChatModel;
      let type = "text";
      let cloudinaryURL: any;
      if (audioURL) {
        type = "audio"
        try {
          const blob = await blobURLtoBlob(audioURL);  // assuming audioURL is your blob URL
             cloudinaryURL = await uploadToCloudinary(blob);
            newChatModel = {
                id: 99999999,
                message: cloudinaryURL,
                timestamp: new Date().getTime().toString(),
                role: 1,
                type: "audio",
                audioURL: cloudinaryURL
            };
        } catch (error) {
            console.error("Error uploading audio:", error);
            setIsAITyping(false);
            return;
        }
    } else {
        newChatModel = {
            id: 99999999,
            message: newMessage,
            timestamp: new Date().getTime().toString(),
            role: 1,
            type: "text"
        };
    }
     
      servicesFunctions.sendChatMessageToChatGPT(newMessage,capitalizeFirstLetterAndTakeOnlyFirstName(isLogin?.name), type, cloudinaryURL).then((res) => {
        setRefresh(!refresh)
        setIsAITyping(false)
        setDeleteMessagesBtn(false)
        
        if(autoPlay){
          handleSpeakClicked(res.audioUrl, messages[messages.length - 1].id + 2, res.reply)
        }
      });
      let updatedMessages: ChatModel[] = [...messages];
      updatedMessages.push(newChatModel);
      setMessages(updatedMessages);
            
    
          setTimeout(() => {
            const AITypingMessage: ChatModel = {
                id: 10000000,
                message: t("chat_typing"),
                timestamp: "",
                role: 0,
              };
              let updatedMessages2: ChatModel[] = [...updatedMessages];
              updatedMessages2.push(AITypingMessage);
              setMessages(updatedMessages2);
          }, 1500);


      // Clear audio URL if an audio message was sent
      if (audioURL) {
          setAudioURL("");
      }
  
      setNewMessage("");   
  }

  function blobURLtoBlob(blobU: any) {
    return fetch(blobU).then(res => res.blob());
}


  function uploadToCloudinary(audioBlob: any) {
    return new Promise((resolve, reject) => {
        let formData = new FormData();
        formData.append('file', audioBlob);
        formData.append('upload_preset', 'ml_default');

        fetch('https://api.cloudinary.com/v1_1/ddubzqehv/upload', {
            method: 'POST',
            body: formData
        })
        .then(response => response.json())
        .then(data => {
            if (data && data.secure_url) {
                resolve(data.secure_url);
            } else {
                reject('Failed to upload audio.');
            }
        })
        .catch(error => {
            reject(error);
        });
    });
}




    function deleteMessages(){
      if(deleteMessagesBtn) return
      setDeleteMessagesBtn(true)
      servicesFunctions.deleteChatMessages().then(() => {
        setRefresh(!refresh)
        document.documentElement.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth", // Optional if you want to skip the scrolling animation
        });

        setTimeout(() => {
          setDeleteMessagesBtn(false)
        }, 2500);
      });
      
    }

    const mediaRecorder = useRef<any>(null);
    const audioChunks = useRef<any[]>([]);

const [showCancel, setShowCancel] = useState(false);
const [recordingTimer, setRecordingTimer] = useState<number>(0);

let recordingInterval: NodeJS.Timeout;  


  const startRecording = async () => {
    // forceStopRecording();
    if (!window.MediaRecorder) {
      toastsFunctions.toastError('MediaRecorder is not supported on this browser/device.');
      return;
  }
  
    navigator.mediaDevices.getUserMedia({ audio: true }).then(stream => {
        audioChunks.current = []; // Clear the previous audio chunks
        mediaRecorder.current = new MediaRecorder(stream);

        setRecordingTimer(0);  // Reset timer to 0 when recording starts
    recordingInterval = setInterval(() => {
        setRecordingTimer(prevTime => prevTime + 1);
    }, 1000);  // Increase timer every 1 second

        mediaRecorder.current.ondataavailable = (event: any) => {
            audioChunks.current.push(event.data);
        };
        mediaRecorder.current.onstop = async () => {

        clearInterval(recordingInterval);

           setIsRecording(false);   // Stop recording
          if (isForceStopped.current) {
              isForceStopped.current = false; // Reset the flag
              setShowCancel(false);    // Hide the cancel button
              return; // Return early to avoid setting audioURL
          }
      
          const blob = new Blob(audioChunks.current, { type: 'audio/wav' });
          setAudioURL(URL.createObjectURL(blob));
      };
      
        mediaRecorder.current.start();
        setIsRecording(true);
            isForceStopped.current = false; 
    });
};


  const stopRecording = () => {
    
    if (mediaRecorder.current) {
        mediaRecorder.current.stop();
        setIsRecording(false);
        setAudioURL("");

    }
};


const [textAreaValue, setTextAreaValue] = useState('');

const forceStopRecording = () => {
  setIsRecording(false);
  setNewMessage("")
  setShowCancel(false);
  
  setAudioURL("");

  
  console.log("Force stopping recording");
  if (mediaRecorder.current) {
      isForceStopped.current = true; 
      mediaRecorder.current.stop();
      const tracks = mediaRecorder.current.stream.getTracks();
      const stream = mediaRecorder.current.stream;
      if (stream && stream.active) {
          stream.getTracks().forEach((track: any) => track.stop());
      }
      tracks.forEach((track: any) => track.stop());

    }
  // Explicitly clear the audioURL
};

function formatTime(seconds: number) {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
}

useEffect(() => {
    if (isRecording) {
      setShowCancel(true);
    } else {
      const timeout = setTimeout(() => {
        setShowCancel(false);
      }, 1000);
      return () => {
        clearTimeout(timeout);
      };
  }
  
}, [isRecording]);


const [currentY, setCurrentY] = useState(0);
const startYRef = useRef(null);
const maxDistance = 40; // Max drag-up distance
const circleRadius = 20; // Radius of the SVG circle
const circumference = 2 * Math.PI * circleRadius; // Circumference of the SVG circle
  const strokeDashOffset = (currentY / maxDistance) * circumference;


const handleTouchStart = (event: any) => {
  console.log("Touch started");
  startYRef.current = event.touches[0].clientY;
  event.target.addEventListener("touchmove", handleTouchMove);
};

const handleTouchMove = (event: any) => {
  const target = event.target.closest('.StartRecordingBtn');
  if (target) {
    event.preventDefault();
  }
  if (!startYRef.current) return;

  let diffY = startYRef.current - event.touches[0].clientY;
  if (diffY < 0) diffY = 0; // prevent dragging downward
  if (diffY > maxDistance) diffY = maxDistance;

  setCurrentY(diffY);
};

const handleTouchEnd = (event: any) => {
  event.target.removeEventListener("touchmove", handleTouchMove);
  if (currentY >= 25) {
    startRecording();
  }
  setCurrentY(0);

};


const [playingMessageId, setPlayingMessageId] = useState(null);

const synth: any = window.speechSynthesis;



const handleSpeakClicked = async (audioUrl: any, messageId: any, messageText: string, voiceLanguage?: string, voiceGender?: string) => {
  const now = Date.now();

  if (now - lastClicked < 500) {
    return;
  }

  audioUrl = audioUrl.replace('.webm', '.mp3');
  audioUrl = audioUrl.replace('.ogg', '.mp3');
  audioUrl = audioUrl.replace('.mp4', '.mp3');
  audioUrl = audioUrl.replace('.wav', '.mp3');
  setLastClicked(now); // Update the last clicked time
  
  if (currentAudio) {
    currentAudio.pause();
    currentAudio.currentTime = 0; 
    URL.revokeObjectURL(currentAudio.src);
    setCurrentAudio(null);
    setPlayingMessageId(null)
  }
  
  if (playingMessageId === messageId) {
    setPlayingMessageId(null)
    setCurrentAudio(null);
    } else {
      // let language;
      // if(voiceLanguage){
      //   language = voiceLanguage
      // } else {
      //   language = userInfo?.teacherLanguage;
      // }
      // let gender;
      // if(voiceLanguage){
      //   gender = voiceGender
      // } else {
      //   gender = userInfo?.teacherGender
      // }

    
    try {
      // const audioData = await servicesFunctions.getGoogleSpeech(messageText, language, gender);
      // const audioUrl = await 
      // if (audioData.audioContent) {
      //   const audioBlob = new Blob([new Uint8Array(atob(audioData.audioContent).split("").map(char => char.charCodeAt(0)))], { type: 'audio/ogg' });
      //   const audioUrl = URL.createObjectURL(audioBlob);
      
        const audio = new Audio(audioUrl);
        audio.setAttribute('playsinline', ''); // Set playsinline attribute
        setCurrentAudio(audio); // Store the audio object in the state

        audio.play();
        console.log(messageId);
        
        setPlayingMessageId(messageId);
        
        audio.onended = () => {
          setPlayingMessageId(null);
        };
      // }

    } catch (error) {
      console.error("Error fetching audio data:", error);
    }
  }
};


useEffect(() => {
  if(isLogin) {
  servicesFunctions.getUserInfo().then((res: any) => {
      setUserInfo(res)
    })
  }
}, [])

function ChangeTeacher() {
  servicesFunctions.setCompletedChatSettings().then(() => {
    servicesFunctions.getUserInfo().then((res: any) => {
      setLanguage(userInfo.teacherLanguage)
      setStep(3)
      setUserInfo(res)
    })
  })
}

function ChangeLanguage() {
  servicesFunctions.setCompletedChatSettings().then(() => {
    servicesFunctions.getUserInfo().then((res: any) => {
      setLanguage(userInfo.teacherLanguage)
      setStep(2)
      setUserInfo(res)
    })
  })
}

const [isSettings, SetIsSettings] = useState(false)


return (
    <div  className={`Chat`}  style={{ direction: i18n.language !== "en" ? "rtl" : "ltr" }}>
      {dotsLoader ? 
        <section className="dots-container">
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
      </section>
      : <></>
    }
    {userInfo?.completedChatSettings !== "true" ? 
    step  === 1 ? 
    <FirstTimeStep1 setStep={setStep}/> :
    step  === 2 ? 
    <FirstTimeStep2 setStep={setStep} language={language} setLanguage={setLanguage}/> :
    step  === 3 ? 
    <FirstTimeStep3 setStep={setStep} language={language} playingMessageId={playingMessageId} handleSpeakClicked={handleSpeakClicked}  choseMaleTeacher={choseMaleTeacher} setChoseMaleTeacher={setChoseMaleTeacher} choseFemaleTeacher={choseFemaleTeacher} setChoseFemaleTeacher={setChoseFemaleTeacher} /> :
    step  === 4 ? 
    <FirstTimeStep4 setStep={setStep} language={language} choseMaleTeacher={choseMaleTeacher} choseFemaleTeacher={choseFemaleTeacher} userInfo={userInfo} setUserInfo={setUserInfo} /> :
    <>{t("chat_failed")}</> :
    <>


        <button className="chat_settings" onClick={() => SetIsSettings(!isSettings)}>
          <svg xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 20 20" height="20" fill="none" className="svg-icon"><g stroke-width="1.5" stroke-linecap="round" stroke="var(--color-light-green)"><circle r="2.5" cy="10" cx="10"></circle><path fill-rule="evenodd" d="m8.39079 2.80235c.53842-1.51424 2.67991-1.51424 3.21831-.00001.3392.95358 1.4284 1.40477 2.3425.97027 1.4514-.68995 2.9657.82427 2.2758 2.27575-.4345.91407.0166 2.00334.9702 2.34248 1.5143.53842 1.5143 2.67996 0 3.21836-.9536.3391-1.4047 1.4284-.9702 2.3425.6899 1.4514-.8244 2.9656-2.2758 2.2757-.9141-.4345-2.0033.0167-2.3425.9703-.5384 1.5142-2.67989 1.5142-3.21831 0-.33914-.9536-1.4284-1.4048-2.34247-.9703-1.45148.6899-2.96571-.8243-2.27575-2.2757.43449-.9141-.01669-2.0034-.97028-2.3425-1.51422-.5384-1.51422-2.67994.00001-3.21836.95358-.33914 1.40476-1.42841.97027-2.34248-.68996-1.45148.82427-2.9657 2.27575-2.27575.91407.4345 2.00333-.01669 2.34247-.97026z" clip-rule="evenodd"></path></g></svg>
          <span className="lable">{t("chat_settings")}</span>
        </button>
        {/* <input type="checkbox" name="autoPlay" id="autoPlay" checked={autoPlay} onChange={() => setAutoPlay(!autoPlay)}/> */}

        <label className={`auto_play_container ${isSettings ? "openSettings" : "closeSettings"}`}>
        <input type="checkbox"  id="autoPlay" checked={autoPlay} onChange={() => setAutoPlay(!autoPlay)}/>
          <p>{t("chat_auto_play")}</p>
        <svg viewBox="0 0 576 512" height="1em" xmlns="http://www.w3.org/2000/svg" className="mute"><path d="M301.1 34.8C312.6 40 320 51.4 320 64V448c0 12.6-7.4 24-18.9 29.2s-25 3.1-34.4-5.3L131.8 352H64c-35.3 0-64-28.7-64-64V224c0-35.3 28.7-64 64-64h67.8L266.7 40.1c9.4-8.4 22.9-10.4 34.4-5.3zM425 167l55 55 55-55c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-55 55 55 55c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-55-55-55 55c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l55-55-55-55c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0z"></path></svg>
        <svg viewBox="0 0 448 512" height="1em" xmlns="http://www.w3.org/2000/svg" className="voice"><path d="M301.1 34.8C312.6 40 320 51.4 320 64V448c0 12.6-7.4 24-18.9 29.2s-25 3.1-34.4-5.3L131.8 352H64c-35.3 0-64-28.7-64-64V224c0-35.3 28.7-64 64-64h67.8L266.7 40.1c9.4-8.4 22.9-10.4 34.4-5.3zM412.6 181.5C434.1 199.1 448 225.9 448 256s-13.9 56.9-35.4 74.5c-10.3 8.4-25.4 6.8-33.8-3.5s-6.8-25.4 3.5-33.8C393.1 284.4 400 271 400 256s-6.9-28.4-17.7-37.3c-10.3-8.4-11.8-23.5-3.5-33.8s23.5-11.8 33.8-3.5z"></path></svg>
        </label>

         <button className={`change_teacher_language_btn ${isSettings ? "openSettings" : "closeSettings"}`} onClick={() => {setOpenDeleteMessagesModal(true)}
          }
          >
          {t("chat_change_teacher")}
         </button>

         <button className={`change_teacher_language_btn ${isSettings ? "openSettings" : "closeSettings"}`} onClick={() => setLanguageOpenDeleteMessagesModal(true)}>
          {t("chat_change_language")}
         </button>
        <ChangeTeacherDeleteMessagesModal openDeleteMessagesModal={openDeleteMessagesModal} setOpenDeleteMessagesModal={setOpenDeleteMessagesModal} ChangeTeacher={ChangeTeacher}/>
        <ChangeLanguageDeleteMessagesModal openLanguageDeleteMessagesModal={openLanguageDeleteMessagesModal} setLanguageOpenDeleteMessagesModal={setLanguageOpenDeleteMessagesModal} ChangeLanguage={ChangeLanguage}/>

        <div className="chat_container" ref={chatContainerRef}  >
    <div className="inner_chat_messages">

    {messages && messages.map((m: ChatModel) => (
    <div key={m.id} >
                {m.role === 0 ? 
                    <div className={m.message === t("chat_typing") ? "Message typing" : "Message "}>
                        <div className="Avatar">
                            <img src={userInfo?.teacherGender == "male" ? siteImages?.imageChatTeacher : siteImages?.imageChatFemaleTeacher} alt="Avatar" />
                        </div>
                        <div className="Content">
                            <div className="Text">{m.message}
                            </div>
                            <div className="play_button" onClick={() => handleSpeakClicked(m.audioURL, m.id, m.message)}>
                              {m.message.includes(t("chat_typing")) ?
                              <></>
                              :
                              <>
                              {playingMessageId === m.id ? <BsFillStopFill /> : <BsFillPlayFill />} 
                              <p>{playingMessageId === m.id ? t("chat_stop") : t("chat_play")}</p>
                              </>
                              }
                          </div>     
                             <div className={m.message === t("chat_typing") ? "Time hidden" : "Time"}>
                                {servicesFunctions.formatTimestamp(m.timestamp)}

                            </div>
                        </div>
                    </div>
                : 
                    <div className={m.message == firstHiddenMessage ? "Message align-right d-none" : "Message align-right"} style={{ direction: i18n.language !== "en" ? "ltr" : "rtl" }}>
                        <div className="Avatar">
                            <Avatar alt={isLogin?.name} src={isLogin?.picture} />
                        </div>
                        <div className="Content">
                        <div className="Text" style={{backgroundColor: "var(--color-white)"}}>
                            {m.type === "audio" ? <div className="custom-audio-control-chat" style={{ left: i18n.language == "en" ? "25px" : "-25px" }}> <audio controls src={m.audioURL} /></div> : m.message}
                        </div>
                            <div className="Time">{servicesFunctions.formatTimestamp(m.timestamp)}</div>
                        </div>
                    </div>
                }
    </div>
))}

        {/* <div className="input_container"> */}
      <div className="chat__conversation-panel">

      <div className="chat__conversation-panel__container">
        <textarea
            onInput={(e: any) => setNewMessage(e.target.value)}
            value={newMessage}
            className="chat__conversation-panel__input panel-item"
            placeholder={isRecording && !audioURL ? `${t("chat_placeholder_recording")} ${formatTime(recordingTimer)}`: audioURL ? "" : t(`chat_placeholder_type_message`)
          }
          
             onKeyDown={(e) => {
    if (e.keyCode === 13 && !e.shiftKey) {
      e.preventDefault(); // Prevent new line
      sendNewMessage(); // Call the function to submit the message
    }
  }}
            ></textarea>

            {audioURL ? 
              <div className="custom-audio-control" style={{right: i18n.language == "en" ? "20%" : "-20%" }}>
                 <audio controls src={audioURL} />
             </div>
              : 
               <></>  
              } 

      {showCancel && (
        <div className={`cancel_record ${isRecording ? '' : 'fade-out'}`} data-lang={i18n.language === "en" ? "en" : "not-en"} onClick={forceStopRecording}>Cancel</div>
      )}


{!isRecording ? 
<>
        <button className="chat__conversation-panel__button" title={isAITyping ? `${t("chat_wait_to_response")}` : "" } onClick={sendNewMessage}>
          {isAITyping ? 
            <div className="loader_btn"></div>  
            :
            <BsFillSendFill
            style={{ fill: "var(--color-light-green)", fontSize: "25px" }}
            />
        }
        </button>

        {audioURL ? 
        <button className="chat__conversation-panel__button" title={isAITyping ? `${t("chat_wait_to_response")}` : "" } onClick={forceStopRecording}>
        <RiDeleteBin6Line
          style={{ fill: "var(--color-light-green)", fontSize: "25px" }}
          />
      </button>           : 
     
      <div style={{position: "relative"}}>

  <button
    className={`chat__conversation-panel__button StartRecordingBtn`}
    style={{ bottom: `${currentY}px`, position: 'relative' }}
    title={isAITyping ? `${t("chat_wait_to_response")}` : ""}
    onTouchStart={handleTouchStart}
    onTouchMove={handleTouchMove}
    onTouchEnd={handleTouchEnd}
    onClick={startRecording} // Keeping the click event in case of non-touch devices
  >
    <BsFillMicFill style={{ fill: "var(--color-light-green)", fontSize: "25px" }} />
  </button>

  <svg 
    height="100" 
    width="100" 
    style={{
      position: "absolute", 
      bottom: `${currentY}px`, 
      left: "30%", 
      transform: "translate(-44%, 36%)",
      display: currentY === 0 ? 'none' : 'block',

    }}
  >
    <circle 
      cx="50" 
      cy="50" 
      r={circleRadius} 
      stroke="transparent" 
      strokeWidth="2" 
      fill="none" 
    />
    <circle
      cx="50"
      cy="50"
      r={circleRadius}
      stroke="var(--color-light-green)"
      strokeWidth="2"
      fill="none"
      strokeDasharray={circumference}
      strokeDashoffset={circumference - strokeDashOffset}
    />
  </svg>

</div>

                  }
            </>

: 
        <button className="chat__conversation-panel__button " title={isAITyping ? `${t("chat_wait_to_response")}` : "" } onClick={stopRecording}>
          <AiOutlinePause
            style={{ fill: "var(--color-light-green)", fontSize: "25px" }}
            />
        </button>
          }
      </div>
    </div>

    {/* </div> */}

      </div>

    </div>
    <button onClick={deleteMessages}  className="delete_chat_history_btn"><span className="text">{t("chat_delete_history_button")}</span><span className="icon"><BsEraserFill/></span></button>


  </>
  }
    </div>
  );
}

export default Chat;
