import "./SiteSource.css";
import { useEffect, useState } from "react";
import { ServicesManagerDashboard } from "../../../../../Services/ServicesManagerDashboard";
import { useTranslation } from "react-i18next";
import { FiSearch, FiLink } from "react-icons/fi";
import { AiOutlineLike } from "react-icons/ai";

function SiteSource(): JSX.Element {

  const [siteReferrers, siteSiteReferrers] = useState<any>();
  const { t } = useTranslation();
  useEffect(() => {
    ServicesManagerDashboard.getSiteReferrers().then((res) => {
      siteSiteReferrers(res)
    })
  }, [])

  return (
    <div className={`rc_SiteSource col-xl-4 col-sm-12 col-lg-5 p-4 col-12 dashboard_box ${siteReferrers ? "" : "loading_container"}`}>

      <div className="pb-2">
        <h4 className="rc_component_title">{t("manager_dashboard_site_soruce")}</h4>
      </div>
      <div className="d-flex justify-content-between rc_info_line">
        <div className="d-flex align-items-center mb-3 gap-2">
          <FiSearch color="var(--box-info-p-color)"/>
          <p>{t("manager_dashboard_site_soruce_search_engines")}</p>
        </div>
        <span>{siteReferrers?.searchEnginesReferrer}</span>
      </div>
      <div className="d-flex justify-content-between rc_info_line">
        <div className="d-flex align-items-center mb-3 gap-2 ">
          <AiOutlineLike color="var(--box-info-p-color)"/>
          <p>{t("manager_dashboard_site_soruce_social_media")}</p>
        </div>
        <span>{siteReferrers?.socialMediaReferrer}</span>
      </div>
      <div className="d-flex justify-content-between rc_info_line">
        <div className="d-flex align-items-center  gap-2 ">
          <FiLink color="var(--box-info-p-color)"/>
          <p>{t("manager_dashboard_site_soruce_other_referrers")}</p>
        </div>
        <span>{siteReferrers?.otherReferrer}</span>
      </div>
    {/* </div> */}
    </div>
  );
}

export default SiteSource;
