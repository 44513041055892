import { useEffect, useState } from "react";
import "./ArticleById.css";
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import { useSelector } from "react-redux";
import { ArticleModel } from "../../../../../../Models/ArticleModel";
import { Link, useNavigate } from "react-router-dom";
import { toastsFunctions } from "../../../../../../Services/ToastFunctions";
import { useParams } from 'react-router-dom';
import { servicesFunctions } from "../../../../../../Services/ServicesFunctions";

import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { useTranslation } from "react-i18next";

import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import DescWordsToHighlight from "../SpecificArticle/descWordsToHighlight";
import TitleWordsToHighlight from "../SpecificArticle/titleWordsToHighlight copy";

function ArticleById(): JSX.Element {
    const isLogin = useSelector((state : any) => state.authSlice)
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [article, setArticles] = useState<ArticleModel>();
    const navigate = useNavigate();
    const { id } = useParams();
    const [wordsToHighlight, setWordToHightlight] = useState<any>([]);
    const [isMarkWords, setIsMarkedWords] = useState<boolean>(false);
    const { t, i18n } = useTranslation();
    useEffect(() => {

        if(!isLogin){
            navigate("/")
            let must_be_logged_in_text = `${t("toast_functions_must_be_logged_in")}`
            toastsFunctions.toastError(must_be_logged_in_text)
        } else {
            if(id){
                servicesFunctions.getArticleByIdByUser(+id).then((res) => setArticles(res)).then(() => {
                    setIsLoading(false)
                })
                servicesFunctions.getAllWordByUser().then((res) => {
                    console.log(res);
                    const englishWords = res.map(item => item.englishWord);
                    console.log(englishWords);
                    setWordToHightlight(englishWords)
                  })
            } else {
                console.log("error there is not a valid id");
            }
        }        
    }, [])

    
  function markWords(e: any) {
    console.log(e.target.checked);
    setIsMarkedWords(e.target.checked)
  }


    return (
        <div className="ArticleById" style={{direction: `${i18n.language === "en" ? "ltr" : "rtl"}`}}>
            <div className="ArticleById_breadCrumbs">
                    <Breadcrumbs aria-label="breadcrumb"  >
                        <Link  color="inherit" to="/">
                           {t("menu_home")}
                        </Link>
                        <Link  color="inherit" to="/dashboard">
                           {t("menu_dashboard")}
                        </Link>
                        <Link
                            color="inherit"
                            to={"/articles"}
                            >
                           {t("menu_all_articles")}
                        </Link>
                        <Typography color="text.primary"> {t("menu_this_article")}</Typography>
                    </Breadcrumbs>
                    </div>
            <div className="ArticleById_top_container">
                    {/* <h1>{t("article_new_article_title")} </h1> */}
            </div>
            <FormControl component="fieldset" onChange={(e) => markWords(e)}>
            <FormGroup aria-label="position" row sx={{display: "flex", justifyContent: "center"}}>
              <FormControlLabel
                value="end"
                control={<Switch  defaultChecked={isMarkWords} color="primary" />}
                label={t("spesefic_article_mark_words")}
                labelPlacement="end"
                sx={{color: "white"}}
              />
            </FormGroup>
          </FormControl>
            <article className="article_main_container">
                {isLoading ? 
                    <Box sx={{ width: 600, justifyContent: 'start', gap: "30px" }}>
                    <Skeleton  sx={{ height: 80 }} />
                    <Skeleton animation="wave"  sx={{ height: 30 }} />
                    <Skeleton animation={false}  sx={{ height: 30 }} />
                    <Skeleton animation="wave"  sx={{ height: 30 }} />
                    <Skeleton animation={false}  sx={{ height: 30 }} />
                    <Skeleton animation="wave"  sx={{ height: 30 }} />
                    <Skeleton animation={false}  sx={{ height: 30 }} />
                    <Skeleton animation="wave"  sx={{ height: 30 }} />
                    <Skeleton animation={false}  sx={{ height: 30 }} />
                    <Skeleton animation="wave"  sx={{ height: 30 }} />
                    <Skeleton animation={false}  sx={{ height: 30 }} />
                    <Skeleton animation="wave"  sx={{ height: 30 }} />
                    <Skeleton animation={false}  sx={{ height: 30 }} />
                  </Box>
                  :
                  <div>
                    
                 {/* <p>{article?.article}</p> */}
                 {isMarkWords? 
                 <TitleWordsToHighlight text={article?.articleTitle} wordsToHighlight={wordsToHighlight}/>
                 : 
                 <h2>{article?.articleTitle}</h2>
                 }
                 {isMarkWords? 
                 <DescWordsToHighlight text={article?.article} wordsToHighlight={wordsToHighlight}/>
                 : 
                 <p>{article?.article}</p>
                 }
                </div>
            }
            </article>
        </div>
    );
}

export default ArticleById;
