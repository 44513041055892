import { useEffect, useState } from "react";
import "./ManagerDashboard.css";
import TopMessage from "./TopMessage/TopMessage";
import SiteData from "./SiteData/SiteData";
import WebInfo from "./WebInfo/WebInfo";
import ManageBlog from "./ManageBlog/ManageBlog";
import SiteSource from "./SiteSource/SiteSource";
import UserIntegrations from "./ImagesSection/ImagesSection";
import ColorsSection from "./ColorsSection/ColorsSection";
import TopWords from "./TopWords/TopWords";
import CustomersChart from "./CustomersChart/CustomersChart";
import ExtenstionData from "./ExtenstionData/ExtenstionData";
import { useTranslation } from "react-i18next";
import { ServicesManagerDashboard } from "../../../../Services/ServicesManagerDashboard";

function ManagerDashboard(): JSX.Element {
    const {  i18n } = useTranslation();
  const [extentionInfo, setExtenstionDataInfo] = useState<any>();
  const [siteStatistic, setSiteStatistic] = useState<any>();
  let [fetchCounter, setFetchCounter] = useState<number>(0)
    useEffect(() => {
    ServicesManagerDashboard.getSiteStatistic().then((res: any) => {
        setSiteStatistic(res)
    })
    }, [])

    useEffect(() => {
      let shouldContinueFetching = true;
  
      const fetchData = async () => {
          if (!shouldContinueFetching) return;
          setFetchCounter(fetchCounter += 1);
  
          try {
              const res = await ServicesManagerDashboard.getExtenstionData();
              setExtenstionDataInfo(res);
  
              if (res.downloads) {
                  shouldContinueFetching = false; // Stop further fetching
              } else {
                  if(fetchCounter >= 3) return;
                  setTimeout(fetchData, 3000);
              }
          } catch (error) {
              if(fetchCounter >= 3) return;
              setTimeout(fetchData, 3000);
          }
      };
  
      fetchData(); // Initial fetch
  
      return () => {
          shouldContinueFetching = false; // Ensure fetching stops when component unmounts
      };
  }, []); // Empty dependency array ensures this effect runs once when component mounts
  

    return (
        <div className="rc_ManagerDashboard p-0 container-fluid d-flex justify-content-center  gap-4 flex-wrap" style={{direction: `${i18n.language === "en" ? "ltr" : "rtl"}`}}>
            <div className="container-fluid py-0  mx-xxl-4 mx-lg-4 px-xl-4  px-lg-5 px-0 row justify-content-center">
			{/* <TopMessage/> */}
            </div>

                    <SiteData extentionInfo={extentionInfo} siteStatistic={siteStatistic} fetchCounter={fetchCounter}/>
                    <ManageBlog/>
                <div className="webinfo_products_tablet_screen col-xl-3  col-12 d-flex flex-xl-column  gap-4 justify-content-xl-evenly  justify-content-center flex-wrap flex-sm-nowrap flex-lg-wrap ">
                    <WebInfo/>
                    <ExtenstionData extentionInfo={extentionInfo}/>
            </div>

            <div className=" col-12 d-flex col   gap-4 gap-lg-4  justify-content-center flex-wrap  test ">
                        <SiteSource/>
                        <UserIntegrations/>

                    <ColorsSection/>
            </div>
                <div className="row col-xl-11  col-lg-10 col-12 gap-4 justify-content-center flex-xl-nowrap ">

                        <TopWords/>
                        <CustomersChart siteStatistic={siteStatistic}/>
                </div>

        </div>
    );
}

export default ManagerDashboard;
