import { useTranslation } from "react-i18next";
import "./PodcastsSection.css";
import { SiApplepodcasts } from "react-icons/si";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { ServicesManagerDashboard } from "../../../../../Services/ServicesManagerDashboard";


function PodcastsSection(): JSX.Element {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const [podcasts, setPodcasts] = useState([])
    useEffect(() => {
        ServicesManagerDashboard.getAllPodcasts().then((res) => {
            setPodcasts(res)
        })
    }, [])
    return (
        <div className="PodcastsSection">
			      <div className="podcasts_container">
                    <h3 className="podcasts_title">{t("blog_podcasts_title")}</h3>
                    <div className="podacsts_boxes">
                        {podcasts?.map((podcast: any) => (
                                    <div className={i18n.language !== "en" ? "podacsts_box pad-right" : "podacsts_box pad-left"}  onClick={() => window.open('https://www.earwolf.com/show/comedy-bang-bang/', '_blank')}>
                                        <div className={i18n.language !== "en" ? "podcast_name_topic podcast text-right" : " podcast_name_topic podcast text-left"}>
                                            <span>{podcast.title}</span>
                                            <span className="podcast_topic">
                                                {i18n.language === "he" ? 
                                                podcast.categoryHE :
                                                podcast.categoryEN}
                                            </span>
                                        </div>
                                        <div className="podcast_icon">
                                            <SiApplepodcasts/>
                                        </div>
                                    </div>
                            ))}
                    </div>
                    </div>
        </div>
    );
}

export default PodcastsSection;
