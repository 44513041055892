import { createSlice } from "@reduxjs/toolkit";
import jwtDecode from "jwt-decode";
import { servicesFunctions } from "../Services/ServicesFunctions";

const token = window.localStorage.getItem('ClickLearnLogged');
let initialState = null;

if (token) {
    const { email, name, picture } : any = jwtDecode(token);
    initialState =  { email, name, picture } ;
}

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        loginRedux: (state, action) => {
                if (!window.localStorage.getItem('ClickLearnLogged')) {
                    servicesFunctions.Register(action.payload).then(() => {
                                      
                        window.localStorage.setItem('ClickLearnLogged', action.payload);
                        const { email, name, picture } : any= jwtDecode(action.payload);
                        state =  { email, name, picture };
                        return state;
                    })
                }
            window.localStorage.setItem('ClickLearnLogged', action.payload);
            const { email, name, picture } : any= jwtDecode(action.payload);
            state =  { email, name, picture };
            return state;
        },
        RegisterFromChat:  (state, action) => {   
            const { credential, teacherLanguage, teacherGender } = action.payload;
            servicesFunctions.RegisterFromChat(credential, teacherLanguage, teacherGender).then(() => {
                window.localStorage.setItem('ClickLearnLogged', credential);
                const { email, name, picture } : any= jwtDecode(credential);
                state =  { email, name, picture };
                return state;
            })
        },
        logoutRedux: (state) => {
            window.localStorage.removeItem('ClickLearnLogged');
            return null;
        },
    }
})

export const { loginRedux, logoutRedux, RegisterFromChat } = authSlice.actions;

export default authSlice.reducer;