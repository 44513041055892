import { useNavigate } from "react-router-dom";
import "./PageNotFound.css";
import { useTranslation } from "react-i18next";

function PageNotFound(): JSX.Element {
    const navigate = useNavigate();
    const { t } = useTranslation();
    return (
        <div className="PageNotFound">
            <div className="pageNotFound_Container">
			<h1 className="notFoundNumber">{t("404_number")}</h1>
			<h1 className="notFountText">{t("404_title")}</h1>
            <button onClick={() => navigate("/dashboard")}>{t("404_button")}</button>

            </div>
        </div>
    );
}

export default PageNotFound;
