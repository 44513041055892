import { Breadcrumbs, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import "./BlogArticle1.css";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { ServicesManagerDashboard } from "../../../../../../Services/ServicesManagerDashboard";
import { useTranslation } from "react-i18next";

interface Article1Images {
  imageArticle1Main: string;
  imageArticle1Second: string;
}
function BlogArticle1(): JSX.Element {
  const siteImages = useSelector((state: any) => state.siteImagesSlice);
  const [articleValue, setArticleValue] = useState<any>({});
  const { t, i18n } = useTranslation();
  
useEffect(() => {
ServicesManagerDashboard.getArticle(1).then((res) => {
  setArticleValue(res);
})
}, [])
  
  return (
    <div className="BlogArticle1" style={{direction: `${i18n.language === "en" ? "ltr" : "rtl"}`}}>
      <div className="blog_container">
        <div className="breadcrumb">
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" to="/">
              {t("menu_home")}
            </Link>
            <Link color="inherit" to={"/blog"}>
              {t("menu_blog")}
            </Link>
            <Typography color="text.primary"> {t("menu_current_article")}</Typography>
          </Breadcrumbs>
        </div>
        <div>
          <h1>
            {i18n.language === "he" ? 
            articleValue.titleHE
          : articleValue.titleEN}
          </h1>
        </div>
        <div>
          <h3>
          {i18n.language === "he" ? 
          articleValue.descriptionHE
          : articleValue.descriptionEN}
          </h3>
        </div>
        <div className="img_top_article">
          <img src={siteImages?.imageArticle1Second} alt="" />
        </div>
        <div>
          
            <p className="article_p"> 
            {i18n.language === "he" ?
            articleValue.bodyHE
          : articleValue.bodyEN}
          </p>
        </div>
      </div>
    </div>
  );
}

export default BlogArticle1;
