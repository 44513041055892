import "./Header.css";
import logo from "./clickLearnNewLogo.png";
import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import { BsSun } from "react-icons/bs";
import { CiSettings } from "react-icons/ci";
import { AiOutlineMenu } from "react-icons/ai";
import { MdDarkMode } from "react-icons/md";
import { BiLogIn, BiLogOut } from "react-icons/bi";
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLight, setDark } from "../../../App/lightDarkSlice";
import Avatar from "@mui/material/Avatar";
import { logoutRedux } from "../../../App/authSlice";
import LoginButton from "../Main/Home/Login/LoginButton/LoginButton";
import "../../../i18n";
import { useTranslation } from "react-i18next";
import IsraelLogo from "./Israel.png";
import arabLogo from "./arab.png";
import USALogo from "./USA.png";
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';


function Header(): JSX.Element {
    const webMode = useSelector((state: any) => state.chosenMode.toggle)
    let dispatch = useDispatch();
    const isLogin = useSelector((state : any) => state.authSlice)
    const siteImages = useSelector((state: any) => state.siteImagesSlice);

    const navigate = useNavigate();
    const [isDarkMode, setIsDarkMode] = useState<boolean>(false)

    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
           };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };



    function clickedWebMode() {
        if(isDarkMode){
            dispatch(setLight())
            setIsDarkMode(!isDarkMode)
        } else {
            dispatch(setDark())
            setIsDarkMode(!isDarkMode)
        }
      }

      const { t, i18n } = useTranslation();


      const languages = [
        { label: 'עברית', value: "he", image: IsraelLogo },
        { label: 'English', value: "en", image: USALogo },
        { label: 'عربي', value: "ar", image: arabLogo },
      ]


    const [language, setLanguage] = React.useState(window.localStorage.getItem("language") ?? "he");

    useEffect(() => {
        i18n.changeLanguage(language);
        if (language === 'en') {
            document.documentElement.setAttribute('dir', 'rtl');
          } else {
            document.documentElement.setAttribute('dir', 'ltr'); 
          }
      }, [language]);

    const changeLanguageHandler = (event: SelectChangeEvent ) => {
        const languageValue = event.target.value
        i18n.changeLanguage(languageValue);
        window.localStorage.setItem("language", languageValue)
        setLanguage(event.target.value as string);

        handleCloseUserMenu()
    };


    return (
        <div className="Header">
          
            <AppBar position="static">

                <Container maxWidth="xl">
                    <Toolbar disableGutters >
                        <Typography
                            variant="h6"
                            noWrap
                            component="a"
                            sx={{
                                mr: 2,
                                display: { xs: 'none', md: 'flex' },
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                letterSpacing: '.3rem',
                                color: '#000000',
                                textDecoration: 'none',
                            }}
                        >

                            <img className="header_logo" style={{cursor: 'pointer'}} src={siteImages?.imageLogo} alt="" onClick={() => navigate("/") } />

                        </Typography>

                        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none'} }}>
                            
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="inherit"
                            >
                                <AiOutlineMenu />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{
                                    display: { xs: 'block', md: 'none' },
                                }}
                                
                            >

                        <MenuItem sx={{justifyContent: 'flex-end', WebkitJustifyContent: 'flex-end'}} onClick={handleCloseNavMenu} component={RouterLink} to="/dashboard">
                        {t("menu_dashboard")}
                        </MenuItem>

                        <MenuItem sx={{justifyContent: 'flex-end', WebkitJustifyContent: 'flex-end'}} onClick={handleCloseNavMenu} component={RouterLink} to="/chat">
                        {t("menu_chat")}
                        </MenuItem>

                        <MenuItem sx={{justifyContent: 'flex-end', WebkitJustifyContent: 'flex-end'}} onClick={handleCloseNavMenu} component={RouterLink} to="/games">
                        {t("menu_games")}
                        </MenuItem>

                        <MenuItem sx={{justifyContent: 'flex-end', WebkitJustifyContent: 'flex-end'}} onClick={handleCloseNavMenu} component={RouterLink} to="/articles">
                        {t("menu_articles")}
                        </MenuItem>

                        <MenuItem sx={{justifyContent: 'flex-end', WebkitJustifyContent: 'flex-end'}} onClick={handleCloseNavMenu} component={RouterLink} to="/blog">
                        {t("menu_blog")}
                        </MenuItem>
                        {isLogin?.email === "harelsarag7@gmail.com" ? 
                        <MenuItem sx={{justifyContent: 'flex-end', WebkitJustifyContent: 'flex-end'}} onClick={handleCloseNavMenu} component={RouterLink} to="/managerDashboard">
                        {t("menu_manager_dashboard")}   
                        </MenuItem>
                        : <></>}


                            </Menu>
                            
                        </Box>
                        <Typography
                            variant="h5"
                            noWrap
                            component="a"
                            sx={{
                                mr: 2,
                                display: { xs: 'flex', md: 'none' },
                                flexGrow: 1,
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                letterSpacing: '.3rem',
                                color: 'inherit',
                                textDecoration: 'none',
                            }}
                        >
                            <img  src={siteImages?.imageLogo} style={{cursor: 'pointer'}} width={150} alt=""  onClick={() => navigate("/")}/>
                        </Typography>
                        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'end', marginRight: '15px' }}>
                        <MenuItem onClick={handleCloseNavMenu}  component={RouterLink} to="/blog">
                            {t("menu_blog")}
                        </MenuItem>

                        <MenuItem onClick={handleCloseNavMenu} component={RouterLink} to="/chat">
                        {t("menu_chat")}
                        </MenuItem>

                        <MenuItem onClick={handleCloseNavMenu} component={RouterLink} to="/games">
                        {t("menu_games")}
                        </MenuItem>

                        <MenuItem onClick={handleCloseNavMenu} component={RouterLink} to="/articles">
                        {t("menu_articles")}
                        </MenuItem>

                        <MenuItem onClick={handleCloseNavMenu} component={RouterLink} to="/dashboard">
                        {t("menu_dashboard")}
                        </MenuItem>
                        {isLogin?.email === "harelsarag7@gmail.com" ? 
                        <MenuItem sx={{justifyContent: 'flex-end', WebkitJustifyContent: 'flex-end'}} onClick={handleCloseNavMenu} component={RouterLink} to="/managerDashboard">
                        {t("menu_manager_dashboard")}   
                        </MenuItem>
                        : <></>}
                        </Box>

                        <Box sx={{ flexGrow: 0 }}>
                            <Tooltip title="Open settings">
                                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0, fontSize: '35px !important' }} >
                                    {isLogin ? 
                                    <Avatar alt={isLogin.name} src={isLogin.picture} />
                                    : 
                                     <CiSettings />
                                    }
                                </IconButton>
                            </Tooltip>
                            <Menu
                                sx={{ mt: '45px' }}
                                id="menu-appbar"
                                anchorEl={anchorElUser}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorElUser)}
                                onClose={handleCloseUserMenu}
                            >
                                {/* {settings.map((setting) => ( */}
                                <MenuItem key={"2"} onClick={() => {
                                    handleCloseUserMenu()
                                    clickedWebMode()
                                    }} sx={{justifyContent: 'end'}}>
                                    {webMode ? 
                                        <Typography sx={{display: 'flex', gap: '10px', alignItems: 'center'}} textAlign="center"> <span>{t("change_to_dark_mode")}</span> <MdDarkMode/></Typography>
                                        :
                                        <Typography  sx={{display: 'flex', gap: '10px', alignItems: 'center'}} textAlign="center"> <span>{t("change_to_light_mode")}</span> <BsSun/></Typography>
                                    }
                                    </MenuItem>
                                {isLogin ?
                                    <MenuItem key={"logout"} sx={{justifyContent: 'end'}} onClick={() => {
                                        handleCloseUserMenu()
                                        dispatch(logoutRedux())
                                    }}>
                                        <Typography sx={{display: 'flex', gap: '10px', alignItems: 'center'}} >{t("logout")}<BiLogIn/></Typography>
                                    </MenuItem>
                                : 
                                    <MenuItem key={"login"} sx={{alignItems: 'end', gap: "5px",flexDirection: "column"}} >
                                        <Typography sx={{display: 'flex', justifyContent: 'end', gap: '10px', alignItems: 'center'}} textAlign="center"> <span>{t("login")}</span> <BiLogIn/>
                                        </Typography>
                                        <LoginButton/>

                                        {/* <Typography   sx={{display: 'flex', textAlign:"right", flexDirection: 'column', gap: '5px'}}><span >{t("login")}<BiLogOut/></span> <LoginButton/></Typography> */}
                                    </MenuItem>
                                }
                                    <MenuItem key={"language"} sx={{justifyContent: 'end'}} >
                                        <div  style={{display: 'flex', flexDirection: 'column', gap: '5px', textAlign: "center" }}>
                                            <div className="custom-select" style={{ width: 180, direction: "rtl" }}>

                                            <Box >
                                                <FormControl sx={{direction: "ltr"}} >
                                                    
                                                    <Select
                                                    id="select-language"
                                                    value={language}
                                                    onChange={changeLanguageHandler}
                                                    variant="outlined"
                                                    sx={{
                                                      display: 'flex',
                                                      alignItems: 'center',
                                                      justifyContent: 'flex-end',
                                                      '& .MuiOutlinedInput-notchedOutline': {
                                                          borderColor: 'transparent', 
                                                        },
                                                          '&:hover': {
                                                            '& .MuiOutlinedInput-notchedOutline': {
                                                              borderColor: 'transparent',
                                                            },
                                                          },
                                                    height: 29,
                                                    }}
                                                    >

                                                    {languages.map((language) => (
                                                        <MenuItem sx={{display: "flex", alignItems: "center", justifyContent:"end"}} key={language.value} value={language.value}>
                                                        {language.label}
                                                        <img src={language.image} alt={language.label} style={{ marginLeft: '14px' }} />
                                                        </MenuItem>
                                                    ))}
                                                    </Select>
                                                </FormControl>
                                                </Box>

                                        </div>
                                        </div>
                                    </MenuItem>
                            </Menu>
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
        </div>




    );
}

export default Header;
