import "./ManageBlog.css";
import  { useEffect, useState } from "react";
import { ServicesManagerDashboard } from "../../../../../Services/ServicesManagerDashboard";
import EditArticleModal from "./EditArticleModal/EditArticleModal";
import EditPodcastModal from "./EditPodcastModal/EditPodcastModal";
import { useTranslation } from "react-i18next";
import { TbArticle } from "react-icons/tb";
import { FiMic } from "react-icons/fi";


function ManageBlog(): JSX.Element {
    const [editArticleModalOpen, setEditArticleModalOpen] = useState<boolean>(false);
    const [editPodcastModalOpen, setEditPodcastModalOpen] = useState<boolean>(false);
    const [articles, setArticles] = useState([]);
    const [podcasts, setPodcasts] = useState([]);
    const [articleValue, setArticleValue] = useState<any>({});
    const [podcastValue, setPodcastValue] = useState<any>({});
    const { t, i18n } = useTranslation();


    useEffect(() => {
        ServicesManagerDashboard.getAllArticles().then((res) => {
            setArticles(res)
        })
        ServicesManagerDashboard.getAllPodcasts().then((res) => {
            setPodcasts(res)
        })
    }, [])

    function openEditArticleModal(articleId: number){
        ServicesManagerDashboard.getArticle(+articleId).then((res) => {
            setArticleValue(res)}).then(res => setEditArticleModalOpen(true))
    }

    function openEditPodcastModal(podcastId: number){
        ServicesManagerDashboard.getPodcast(+podcastId).then((res) => {
            setPodcastValue(res)}).then(res => setEditPodcastModalOpen(true))
    }



    return (
        <div className={`rc_ManageBlog col-xl-4 p-3 col-lg-5 col-12 dashboard_box  ${articles ? "" : "loading_container"}`}>

        <div className="d-flex justify-content-between">
                    <h4 className="rc_component_title">{t("manager_dashboard_blog")}</h4>

                    <div className="d-flex align-items-center gap-3">
                        {/* <p>כל הכתבות בעמוד הבלוג</p> */}

                    </div>
                </div>
            <div className="d-flex justify-content-between">
                <div className="d-flex align-items-center gap-2">
                    <TbArticle color="var(--box-info-p-color)"/>
                <p style={{fontWeight: "bold"}}>{t("manager_dashboard_blog_articles")}</p>
                </div>
            </div>
            {Array.isArray(articles) && articles.map((article: any) => (
        <div className="d-flex justify-content-between manage_blog_line" key={article?.id}>
            <div className="d-flex align-items-center gap-2 title_id_line" >
            <p>{`${t("manager_dashboard_blog_article")} ${article?.id} - `}</p>
            <span className="truncate title">{i18n.language == "he" ? article?.titleHE : i18n.language == "ar" ? article.titleAR : article.titleEN}</span>
            </div>
            <button onClick={() => openEditArticleModal(article?.id)}>{t("manager_dashboard_blog_edit")}</button>
        </div>
        ))}

            <div className="d-flex justify-content-between mt-3">
                <div className="d-flex align-items-center gap-2">
                <FiMic color="var(--box-info-p-color)"/>
                <p style={{fontWeight: "bold"}}>{t("manager_dashboard_blog_podcasts")}</p>
                </div>
            </div>

            {Array.isArray(podcasts) && podcasts.map((podcast: any) => (
                <div key={podcast.id} className="d-flex justify-content-between manage_blog_line">
                <div className="d-flex align-items-center gap-2">
                <p>{t("manager_dashboard_blog_podcast")} {podcast.id} - </p>
                <span  className="truncate title">{podcast.title}</span>
                </div>
                <button onClick={() => openEditPodcastModal(podcast.id)}>{t("manager_dashboard_blog_edit")}</button>
            </div>
                ))}

            <EditArticleModal editArticleModalOpen={editArticleModalOpen} setEditArticleModalOpen={setEditArticleModalOpen} articleValue={articleValue}/>
            <EditPodcastModal editPodcastModalOpen={editPodcastModalOpen} setEditPodcastModalOpen={setEditPodcastModalOpen} podcastValue={podcastValue}/>

        </div>
    );
}

export default ManageBlog;
