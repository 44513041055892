import { useDispatch, useSelector } from "react-redux";
import { ServicesManagerDashboard } from "../../../../../Services/ServicesManagerDashboard";
import "./ImagesSection.css";
import { MdModeEdit } from "react-icons/md";
import { toastsFunctions } from "../../../../../Services/ToastFunctions";
import { setSiteImages } from "../../../../../App/siteImages";
import { useTranslation } from "react-i18next";
import Tooltip from '@mui/material/Tooltip';
import { useState } from "react";
import PreviewImageModal from "./PreviewImageModal/PreviewImageModal";

function ImagesSection(): JSX.Element {
  const siteImages = useSelector((state: any) => state.siteImagesSlice);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [previewImageModalOpen, setPreviewImageModalOpen] = useState<boolean>(false);
  const [previewImage, setPreviewImage] = useState<any>({});

function uploadedFile(key: string, e : React.ChangeEvent<HTMLInputElement>){
  if(e.target.files && e.target.files.length > 0){
      let img: string = URL.createObjectURL(e.target.files[0] );
      ServicesManagerDashboard.uploadImage(key, e).then(res => {
          if(res === 400){
              toastsFunctions.toastError('Please Upload a jpeg or png file')
          }

          const updatedSiteImages = {
            ...siteImages,
            [key]: res // "key" is dynamic, so we use bracket notation
          };
          dispatch(setSiteImages(updatedSiteImages));
      })
      }
  }

  function openPreviewImage(key: string){
    const imageUrl = siteImages[key];
    
    if (imageUrl) {
      setPreviewImage({imageUrl, key});
      setPreviewImageModalOpen(true);
    }
  }
  return (
    <div className={`rc_ImagesSection col-xl-4 col-sm-12 col-lg-5 p-4 col-12 dashboard_box ${siteImages ? "" : "loading_container"}`}>

      <div className="pb-2">
        <h4 className="rc_component_title">{t("manager_dashboard_change_images")}</h4>
      </div>

      <div className="container">
  
                {Object.keys(siteImages)?.map((key, index) => (
                  <div key={index} title={key}>
                      <Tooltip title={key}>
                    <div className="avatar-upload">
                      <div className="avatar-edit">
                        <input
                          type="file"
                          id={`imageUpload-${key}`}
                          accept=".png, .jpg, .jpeg"
                          // onChange={handleImageUploadChange}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {uploadedFile(key, e)}} 
                        />
                        <label htmlFor={`imageUpload-${key}`}><MdModeEdit/></label>
                      </div>
                      <div className="avatar-preview" onClick={() => openPreviewImage(key)}>
                        <div id={`imagePreview-${key}`} style={{ backgroundImage: `url("${siteImages[key]}")` }}></div>
                      </div>
                    </div>
                    </Tooltip>
                  </div>
                ))}

            </div>
            <PreviewImageModal previewImageModalOpen={previewImageModalOpen} setPreviewImageModalOpen={setPreviewImageModalOpen} previewImage={previewImage}/>

    </div>
  );
}

export default ImagesSection;
