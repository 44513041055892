import "./EditPodcastModal.css";
import * as React from 'react';
import Button from '@mui/joy/Button';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import { ServicesManagerDashboard } from "../../../../../../Services/ServicesManagerDashboard";
import { useTranslation } from "react-i18next";


function EditPodcastModal( { editPodcastModalOpen, setEditPodcastModalOpen, podcastValue }: { editPodcastModalOpen: boolean, setEditPodcastModalOpen: (e: boolean) => void, podcastValue: any}): JSX.Element {
  const { t, i18n } = useTranslation();
  async function NewPodcastValue(newPodcast: any) {
    ServicesManagerDashboard.editPodcast(podcastValue.id, newPodcast)
  }

    return (
        <div>
<React.Fragment>
      <Modal open={editPodcastModalOpen} onClose={() => setEditPodcastModalOpen(false)}>
        <ModalDialog
          aria-labelledby="basic-modal-dialog-title"
          aria-describedby="basic-modal-dialog-description"
          sx={{ maxWidth: 500, width: "70%", direction:  `${i18n.language === "en" ? "ltr" : "rtl"}`}}
        >
          <Typography id="basic-modal-dialog-title" level="h2">
            {t("manager_dashboard_blog_edit_podcast")}
          </Typography>
          <Typography id="remember_edit_languages">
            *{t("manager_dashboard_blog_edit_podcast_remember")}
          </Typography>
          <form
            onSubmit={(event:any) => {
              event.preventDefault();
              let newPodcast = {
                title: event.target[0].value,
                categoryHE: event.target[1].value,
                categoryEN: event.target[2].value,
                categoryAR: event.target[3].value,
              }
              
              NewPodcastValue(newPodcast)
              setEditPodcastModalOpen(false);
            }}
          >
            <Stack spacing={2}>
              <FormControl >
                <FormLabel>{t("manager_dashboard_blog_edit_podcast_name")}</FormLabel>
                <Input autoFocus required defaultValue={podcastValue?.title}/>
              </FormControl>
              <FormControl>
                <FormLabel>{t("manager_dashboard_blog_edit_podcast_category_hebrew")}</FormLabel>
                <Input defaultValue={podcastValue?.categoryHE} />
              </FormControl>
              <FormControl>
                <FormLabel>{t("manager_dashboard_blog_edit_podcast_category_english")}</FormLabel>
                <Input defaultValue={podcastValue?.categoryEN} />
              </FormControl>
              <FormControl>
                <FormLabel>{t("manager_dashboard_blog_edit_podcast_category_arabic")}</FormLabel>
                <Input defaultValue={podcastValue?.categoryAR} />
              </FormControl>
              <Button type="submit">{t("manager_dashboard_blog_edit_podcast_accept")}</Button>
            </Stack>
          </form>
        </ModalDialog>
      </Modal>
    </React.Fragment>
        </div>
    );
}

export default EditPodcastModal;

