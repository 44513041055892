import "./Login.css";
import { useSelector } from "react-redux";
import LoginButton from "./LoginButton/LoginButton";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { servicesFunctions } from "../../../../../Services/ServicesFunctions";


function Login(): JSX.Element {
const navigate = useNavigate();
const isLogin = useSelector((state : any) => state.authSlice)
const { t } = useTranslation();
const siteImages = useSelector((state: any) => state.siteImagesSlice);

    return (
      <div className="Login">

        <div className="Text_Conatiner">
          <span>😎</span>
          <h3>{t("home_login_title")}</h3>
        </div>
        <div className="login_image_and_google">
          <div>
            <img src={siteImages?.loginImage} alt="" />
          </div>

          <div className="login_google">
            <div className="click_here_icon">👇</div>
            {isLogin ? (
            <button onClick={() => navigate("/dashboard")}>{t("home_login_button")}</button>
            ) : (
         <LoginButton/>
        )}

          </div>
        </div>
      </div>
    );
}

export default Login;
