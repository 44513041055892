import * as React from 'react';
import Button from '@mui/joy/Button';
import Divider from '@mui/joy/Divider';
import { GoAlertFill } from 'react-icons/go'
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import { useTranslation } from 'react-i18next';

export default function ChangeLanguageDeleteMessagesModal({openLanguageDeleteMessagesModal, setLanguageOpenDeleteMessagesModal, ChangeLanguage}: {openLanguageDeleteMessagesModal: boolean, setLanguageOpenDeleteMessagesModal: (e: boolean) => void, ChangeLanguage: () => void}) {
 const { t } = useTranslation();
  function handleClickedDelete(){
    setLanguageOpenDeleteMessagesModal(false)
    ChangeLanguage()
  }
  
  return (
    <React.Fragment>
      <Modal open={openLanguageDeleteMessagesModal} onClose={() => setLanguageOpenDeleteMessagesModal(false)}>
        <ModalDialog variant="outlined" role="alertdialog">
          <title style={{display: "flex", gap: "20px", justifyContent: "center", alignItems: "center"}}>
            <GoAlertFill/>
            {t("chat_modal_title")}
          </title>
          <Divider />
          <div style={{margin: "0 auto 20px auto"}}>
            {t("chat_modal_desc_language")}
          </div>
          <div style={{display: "flex", gap: "20px", justifyContent: "center", alignItems: "center"}}>
            <Button variant="solid" color="danger" onClick={handleClickedDelete}>
            {t("chat_modal_delete")}
            </Button>
            <Button variant="plain" color="neutral" onClick={() => setLanguageOpenDeleteMessagesModal(false)}>
            {t("chat_modal_cancel")}
            </Button>
          </div>
        </ModalDialog>
      </Modal>
    </React.Fragment>
  );
}