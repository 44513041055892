import "./WebInfo.css";
import { useEffect, useState } from "react";
import { ServicesManagerDashboard } from "../../../../../Services/ServicesManagerDashboard";
import {webInfo} from "../../../../../Models/siteModel"
import { useTranslation } from "react-i18next";
import { FaRegUser, FaRegBell } from "react-icons/fa";
import { TbLogin } from "react-icons/tb";
import SubscribersModal from "./SubscribersModal/SubscribersModal";

function WebInfo(): JSX.Element {
    const [webInfo, setWebInfo] = useState<webInfo>();
    const { t } = useTranslation();

    useEffect(() => {
        ServicesManagerDashboard.getWebInfo().then((res: webInfo) => {
            setWebInfo(res);
        })
    }, [])


    const [editSubscriberModalOpen, setEditSubscriberModalOpen] = useState<boolean>(false);

    return (
    <div className={`rc_WebInfo col-xl-12 col-sm-6 col-lg-5 p-4 col-12 rc_dashboard_box ${webInfo ? "" : "loading_container"}`}>

                <div className="pb-2"  >
                    <h4 className="rc_component_title">{t("manager_dashboard_site_data")}</h4>
                </div>
                <div className="d-flex justify-content-between rc_info_line">
                    <div className="d-flex align-items-center gap-2">
                       <FaRegUser color="var(--box-info-p-color)"/>
                       <p>{t("manager_dashboard_site_data_amount_users")}</p>
                    </div>
                    <span>{webInfo?.usersAmount}</span>
                </div>
                <div className="d-flex justify-content-between rc_info_line subscribers_line" onClick={() => setEditSubscriberModalOpen(true)}>
                    <div className="d-flex align-items-center gap-2">
                       <FaRegBell color="var(--box-info-p-color)"/>
                       <p>{t("manager_dashboard_site_data_subscribers")}</p>
                    </div>
                    <span>{webInfo?.subscribersAmount}</span>
                </div>
                <div className="d-flex justify-content-between rc_info_line ">
                    <div className="d-flex align-items-center gap-2">
                       <TbLogin color="var(--box-info-p-color)"/>
                       <p>{t("manager_dashboard_site_data_site_views")}</p>
                    </div>
                    <span >{webInfo?.siteVisitsAmount}</span>
                </div>


            <SubscribersModal editSubscriberModalOpen={editSubscriberModalOpen} setEditSubscriberModalOpen={setEditSubscriberModalOpen} />
        </div>
    );
}

export default WebInfo;
