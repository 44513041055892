import { useNavigate } from "react-router-dom";
import "./Games.css";
import memoryGamePhoto from './memory-game-photo.webp';
import quizGamePhoto from './quiz-game-photo.webp';
import translatebyTime from './translateByTime.webp';
import { servicesFunctions } from "../../../../../Services/ServicesFunctions";
import { toastsFunctions } from "../../../../../Services/ToastFunctions";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

function Games(): JSX.Element {

    const navigate = useNavigate();
    const isLogin = useSelector((state : any) => state.authSlice)
    const { t } = useTranslation();
  const siteImages = useSelector((state: any) => state.siteImagesSlice);
       
    const  handleMemoryGameClick = async () =>  {
        if (!isLogin) {
            navigate("/");
            let must_be_logged_in_text = `${t("toast_functions_must_be_logged_in")}`
            toastsFunctions.toastError(must_be_logged_in_text);
          } 
        const totalWords = await servicesFunctions.getAllWordByUser();
        if(totalWords?.length < 6 ){
            let must_have_minimum_6_words = `${t("toast_functions_memory_at_least_6_words")}`
            toastsFunctions.toastError(must_have_minimum_6_words)   
        }else{
            navigate("/memorygame")
        }
      };

    return (
        <div className="Games">
			<div className="games_top_container">
                {/* <img className="background_top_image" src="https://img.freepik.com/free-photo/top-view-interlocking-plastic-blocks-baby-shower_23-2148430489.jpg?w=1480&t=st=1680540345~exp=1680540945~hmac=4041335935a7e1c94afbb978943134555af96179462c0eb366c841e6a2bf083e" alt="" /> */}
                <h1 className="top_text">{t("games_title")}</h1>
            </div>

            <div className="games_container">
                
                <div className="game_box" onClick={() => navigate("/TranslateWithTimer")}>
                        <h4>{t("translate_game_title")}</h4>
                    <img src={siteImages.imageTranslateGame} alt="" />
                </div>

                <div className="game_box" onClick={() => navigate("/quiz")}>
                        <h4>{t("quiz_game_title")}</h4>
                    <img src={siteImages.imageQuizGame} alt="" />
                </div>

                <div className="game_box " onClick={handleMemoryGameClick}>
                        <h4>{t("memory_game_title")}</h4>
                    <img src={siteImages.imageMemoryGame} alt=""  />
                </div>
            </div>
            
        </div>
    );
}

export default Games;
