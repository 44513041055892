import "./SiteData.css";
import up from "./up.png";
import down from "./down.png";
import SiteDataChart from "./SiteDataChart/SiteDataChart";
import { useState } from "react";
import { useTranslation } from "react-i18next";

function SiteData({ extentionInfo, siteStatistic, fetchCounter } : {extentionInfo: any, siteStatistic: any, fetchCounter: number}): JSX.Element {
    // const [siteData, setSiteData] = useState<any>();
    const [period , setPeriod] = useState("חודש שעבר");
    const { t } = useTranslation();

    return (
        <div className={`rc_SiteData col-xl-4 p-3 col-lg-5 col-12 rc_dashboard_box justify-content-evenly d-flex flex-column ${extentionInfo ? "" : "loading_container"}`}>
                

                    <div className="d-flex justify-content-between">
                        <h4 className="rc_component_title">{t("manager_dashboard_site_statistic")}</h4>

                        <div className="d-flex align-items-center gap-3">
                            {/* <p>לפי חודש שעבר</p> */}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-7 col-12">
                        <div className="data_container">
                <div className="data_text_container">
                    <div className="data_text">
                        <div className="data_text_title">
                            <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                                <rect x="10" y="10" width="80" height="80" fill="#008FFB" />
                            </svg>
                            <p>{t("manager_dashboard_site_statistic_amount_web_views")}</p>
                        </div>

                        <div className="line_two_container">
                            {extentionInfo ? 
                            
                            <p className="data_big_number">{siteStatistic?.webviews ?? <div className={fetchCounter >= 3 ? "" : `loader`}></div>}</p>
                        : <></>}
                            {/* <img width={20} height={20} src={extentionInfo?.ThisMonthSumCustomers > extentionInfo?.LastMonthSumCustomers ? up : down} alt="" /> */}
                        </div>
                        <div className="line_three_container">
                            {/* <p>{t("manager_dashboard_site_statistic_compared_to_last_month")}</p> */}
                        </div>
                    </div>
                    <div className="data_text">
                        <div className="data_text_title">
                            <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                                <rect x="10" y="10" width="80" height="80" fill="#FEB019" />
                            </svg>
                            <p>{t("manager_dashboard_site_statistic_amount_extenstion_downloads")}</p>
                        </div>

                        <div className="line_two_container">
                            <p className="data_big_number">{extentionInfo?.downloads ?? <div className="loader"></div>}</p>
                            {/* <img width={20} height={20} src={extentionInfo?.ThisMonthSumSales > extentionInfo?.LastMonthSumSales ? up : down} alt="" /> */}
                        </div>
                        <div className="line_three_container">
                            <p></p>
                        </div>
                    </div>
                    <div className="data_text">
                        <div className="data_text_title">
                            <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                                <rect x="10" y="10" width="80" height="80" fill="#00E396" />
                            </svg>
                            <p>{t("manager_dashboard_site_statistic_amount_words_translated")}</p>
                        </div>

                        <div className="line_two_container">
                            <p className="data_big_number">{extentionInfo?.totalWordsTranslated ?? <div className="loader"></div>}</p>
                            {/* <img width={20} height={20} src={extentionInfo?.thisMonthProfit > extentionInfo?.lastMonthProfit ? up : down} alt="" /> */}
                        </div>
                        <div className="line_three_container">
                            {/* <p>{t("manager_dashboard_site_statistic_compared_to_last_month")}</p> */}
                        </div>
                    </div>
                </div>

                </div>

                        </div>
                        <div className="col-sm-5 col-12  my-sm-0 my-4 justify-content-center justify-content-lg-center justify-content-xxl-end    align-items-end d-flex">
                        <div className="data_chart">
                        <SiteDataChart extentionInfo={extentionInfo?extentionInfo : {}} siteStatistic={siteStatistic} fetchCounter={fetchCounter} />
                        </div> 
                        </div>
            
                    </div>
                <div className="spliter"></div>
                    <div>

                    <div className="data_footer_container mt-3 ">
                        {/* <div className="d-flex gap-2 flex-row">
                            <p>{t("manager_dashboard_site_statistic_new_user_mounth")}:</p>
                            <span>{extentionInfo?.avgOrderNum} </span>
                        </div> */}
                        <div className="d-flex gap-2 flex-row">
                            {/* <p>{t("manager_dashboard_site_statistic_percentage_to_paying_customer")}</p> */}
                            <p>{t("manager_dashboard_site_statistic_percentage_retention")}:</p>
                            <span>{(siteStatistic?.returnCustomers / siteStatistic?.newCustomers) * 100}%</span>
                        </div>
                        <div className="d-flex gap-2 flex-row">
                            <p>{t("manager_dashboard_site_statistic_percentage_to_paying_customer")}:</p>
                            {/* <p>{t("manager_dashboard_site_statistic_percentage_retention")}:</p> */}
                            <span>0%</span>
                        </div>
                        {/* <div className="d-flex gap-2 flex-row">
                            <p></p>
                            <span>{siteData?.productsInStock}</span>
                        </div> */}
                    </div>
                </div>
            </div>

    );
}

export default SiteData;

