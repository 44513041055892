import "./PreviewImageModal.css";
import * as React from 'react';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import Typography from '@mui/joy/Typography';
import { useTranslation } from "react-i18next";


function PreviewImageModal( { previewImageModalOpen, setPreviewImageModalOpen, previewImage }: { previewImageModalOpen: boolean, setPreviewImageModalOpen: (e: boolean) => void, previewImage: any}): JSX.Element {
  const { t, i18n } = useTranslation();


    return (
        <div>
<React.Fragment>
      <Modal open={previewImageModalOpen} onClose={() => setPreviewImageModalOpen(false)}>
        <ModalDialog
          aria-labelledby="basic-modal-dialog-title"
          aria-describedby="basic-modal-dialog-description"
          sx={{ maxWidth: 500, width: "70%", direction:  `${i18n.language === "en" ? "ltr" : "rtl"}` }}
        >
          <Typography id="basic-modal-dialog-title" level="h2">
            {t("manager_dashboard_preview_image")}
          </Typography>
          <Typography id="basic-modal-dialog-description">
            *{previewImage.key}
          </Typography>
        <img src={previewImage.imageUrl} alt="" style={{borderRadius: "10px", marginTop: "15px"}} />
        </ModalDialog>
      </Modal>
    </React.Fragment>
        </div>
    );
}

export default PreviewImageModal;

