import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toastsFunctions } from "../../../../../../Services/ToastFunctions";
import "./SpecificArticle.css";
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import { Breadcrumbs, Typography } from "@mui/material";
import { servicesFunctions } from "../../../../../../Services/ServicesFunctions";
import loadingGIF from "./loadingGIF.gif";
import DescWordsToHighlight from "./descWordsToHighlight";
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { useTranslation } from "react-i18next";
import TitleWordsToHighlight from "./titleWordsToHighlight copy";

function SpecificArticle(): JSX.Element {
    const [description, setDescription] = useState<string>("");
    const [title, setTitle] = useState<string>("");
    
    const isLogin = useSelector((state : any) => state.authSlice);
    const { t, i18n } = useTranslation();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const navigate = useNavigate();
    const [hasLoaded, setHasLoaded] = useState(false);
    const [wordsToHighlight, setWordToHightlight] = useState<any>([]);
    const [isMarkWords, setIsMarkedWords] = useState<boolean>(false);
  
    
  useEffect(() => {
    if(!isLogin){
      navigate("/")
      toastsFunctions.toastError("אנא התחבר בכדי להמשיך...")
    } else {
      if(!hasLoaded && !description){
        setIsLoading(true);
        servicesFunctions.createNewArticle().then((res: any) => {
          // res = JSON.parse(res);
          setDescription(res.article)
          setTitle(res.articleTitle ?? "אירעה שגיאה, נסה/י שוב מאוחר יותר")
          setIsLoading(false)
          setHasLoaded(true);
        });

        servicesFunctions.getAllWordByUser().then((res) => {
          console.log(res);
          const englishWords = res.map(item => item.englishWord);
          console.log(englishWords);
          setWordToHightlight(englishWords)
        })
      }
                
    }
  }, [description, hasLoaded]);




  function markWords(e: any) {
    console.log(e.target.checked);
    setIsMarkedWords(e.target.checked)
    
  }
    return (
        <div className="SpecificArticle" style={{direction: `${i18n.language === "en" ? "ltr" : "rtl"}`}}>
                        <div className="ArticleById_breadCrumbs">
                    <Breadcrumbs aria-label="breadcrumb"  >
                        <Link  color="inherit" to="/">
                            דף הבית
                        </Link>
                        <Link  color="inherit" to="/dashboard">
                            איזור אישי
                        </Link>
                        <Link
                            color="inherit"
                            to={"/articles"}
                            >
                            כל המאמרים
                        </Link>
                        <Typography color="text.primary"> מאמר הנוכחי</Typography>
                    </Breadcrumbs>
                    </div>
            <div className="specificArticle_top_container">
                    {/* <h1>מאמר חדש </h1> */}
            </div>
            
            <FormControl component="fieldset" onChange={(e) => markWords(e)}>
          <FormGroup aria-label="position" row sx={{display: "flex", justifyContent: "center"}}>
            <FormControlLabel
              value="end"
              control={<Switch  defaultChecked={isMarkWords} color="primary" />}
              label={t("spesefic_article_mark_words")}
              labelPlacement="end"
              sx={{color: "white"}}
            />
          </FormGroup>
        </FormControl>
            <article className="article_main_container">
                {isLoading ? 
                <div>
                  <div className="loader_container">
                    <p>כותבים עבורך את המאמר עם המילים המועדפות שלך...</p>
                  <img src={loadingGIF} />
                  </div>
                    <div className="loader_container timeText"> 
                      <p>פעולה זו עשויה להימשך כדקה</p>
                    </div>
                <div>

                    <Box   sx={{
                      width: 750,
                        justifyContent: 'start',
                        gap: '30px',
                        margin: '0 auto',
                        '@media (max-width: 866px)': {
                          width: '90%',
                          margin: '0 auto',
                        },
                      }}>
                    <Skeleton  sx={{ height: 80 }} />
                    <Skeleton animation="wave"  sx={{ height: 30 }} />
                    <Skeleton animation={false}  sx={{ height: 30 }} />
                    <Skeleton animation="wave"  sx={{ height: 30 }} />
                    <Skeleton animation={false}  sx={{ height: 30 }} />
                    <Skeleton animation="wave"  sx={{ height: 30 }} />
                    <Skeleton animation={false}  sx={{ height: 30 }} />
                    <Skeleton animation="wave"  sx={{ height: 30 }} />
                    <Skeleton animation={false}  sx={{ height: 30 }} />
                    <Skeleton animation="wave"  sx={{ height: 30 }} />
                    <Skeleton animation={false}  sx={{ height: 30 }} />
                    <Skeleton animation="wave"  sx={{ height: 30 }} />
                    <Skeleton animation={false}  sx={{ height: 30 }} />
                  </Box>
                      </div>
                  </div>
                  :
                  <div>
                    
                 {isMarkWords? 
                 <TitleWordsToHighlight text={title} wordsToHighlight={wordsToHighlight}/>
                 : 
                 <h2>{title}</h2>
                 }
                 {isMarkWords? 
                 <DescWordsToHighlight text={description} wordsToHighlight={wordsToHighlight}/>
                 : 
                 <p>{description}</p>
                 }
                </div>
            }
            </article>

        </div>
    );
}

export default SpecificArticle;
