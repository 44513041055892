import "./ColorsSection.css";
import { useEffect, useState } from "react";
import { ServicesManagerDashboard } from "../../../../../Services/ServicesManagerDashboard";
import { servicesFunctions } from "../../../../../Services/ServicesFunctions";
import { SiteColors } from "../../../../../Models/siteModel";
import { setMainColor } from "../../../../../App/siteColors";
import { useDispatch } from 'react-redux';
import { useTranslation } from "react-i18next";
import { IoMdColorFill } from "react-icons/io";

function ColorsSection(): JSX.Element {

  const [siteCustomersByDevice, setColorsSection] = useState<any>();
  const [siteColors, setSiteColors] = useState<SiteColors>();
  const [newMainColor, setNewMainColor] = useState<string>();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  
  useEffect(() => {
    ServicesManagerDashboard.getColorsSection().then((res: any) => {
      setColorsSection(res)
    })

    servicesFunctions.getSiteColors().then((res: SiteColors) => {
      setSiteColors(res);
    })
  }, [])


  async function changeMainColor(e: any){
    let color = e.target.value.toString()
    setNewMainColor(e.target.value);
    const res = await ServicesManagerDashboard.changeMainColorSite(color);

    dispatch(setMainColor(e.target.value));
  }


  return (

    <div className={`rc_ColorsSection  col-xl-3  p-4 col-lg-6 col-12 dashboard_box ${siteCustomersByDevice ? "" : "loading_container"}`}>

      <div className="pb-2">
        <h4 className="rc_component_title">{t("manager_dashboard_change_colors")}</h4>
      </div>
      <div className="d-flex justify-content-between rc_info_line">
        <div className="d-flex align-items-center gap-2">
            <IoMdColorFill color="var(--box-info-p-color)"/>
          <p>{t("manager_dashboard_change_colors_main")}</p>
        </div>
        { siteColors?.mainColor ? 
        <input type="color" onChange={changeMainColor} defaultValue={`${siteColors?.mainColor}`} id="main_color"/>
        : <></>}
      </div>
    </div>

  );
}

export default ColorsSection;
