import "./CustomersChart.css";
import React, { useEffect, useState } from "react";
import { Bar } from 'react-chartjs-2';
import { ServicesManagerDashboard } from "../../../../../Services/ServicesManagerDashboard";

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Interaction
    // Title,
    // Tooltip,
    // Legend,
  } from 'chart.js';
import { useTranslation } from "react-i18next";
  
  
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    // Title,
    // Tooltip,
    // Legend
  );






function CustomersChart({siteStatistic}) {
    const [customerTypes, setCustomerTypes] = useState([
      // { month: 'Jan', knownCustomers: 50, newCustomers: 20 },
      // { month: 'Feb', knownCustomers: 40, newCustomers: 30 },
      { month: '', knownCustomers: 60, newCustomers: 25 }]);
    const { t} = useTranslation();
    useEffect(() => {
        // ServicesManagerDashboard.getCustomerTypes().then((res) => {
        //     setCustomerTypes(res);
        // })
    }, [])

 
const labels = Array.isArray(customerTypes) ? customerTypes.map((type) => type.month) : [];

    const data = {
      labels,
      datasets: [
        {
          label: `${t("manager_dashboard_customers_old_users")}`,
          // Use a ternary operator to check if customerTypes is truthy before calling map()
          // data: Array.isArray(customerTypes) ? customerTypes.map((type) => type.knownCustomers) : [],
          data:[siteStatistic?.returnCustomers],
          backgroundColor: '#3D9FC7',
        },
        {
          label: `${t("manager_dashboard_customers_new_users")}`,
          // Use a ternary operator to check if customerTypes is truthy before calling map()
          // data: Array.isArray(customerTypes) ? customerTypes.map((type) => type.newCustomers) : [],
          data:[(siteStatistic?.newCustomers - siteStatistic?.returnCustomers)],
          backgroundColor: '#3DC7AD',
        },
      ],
    };
    
  
    

const options = {
  plugins: {
    title: {
      display: true,
    },
  },
  responsive: true,
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false,
      },
    },
    y: {
      stacked: true,
    },
  },
  layout: {
    padding: {
      left: 20,
      right: 20,
      top: 0,
      bottom: 0,
    },
  },
  indexAxis: "x",
  barPercentage: 1,
  categoryPercentage: 0.3,
};


    return (
        <div className={`rc_CustomersChart col-xl-6 col-12  p-4 rc_dashboard_box ${customerTypes ? "" : "loading_container"} d-flex flex-column `}>
        
            <div className="pb-2">
                <h4 className="rc_component_title">{t("manager_dashboard_customers")}</h4>
            </div>

            <div className="rc_chart_wrapper d-flex flex-column gap-4 justify-content-center align-items-center">
                <Bar data={data} options={options} />
          <div className="d-flex justify-content-center gap-5">
                    <div className="chart_types d-flex ">
                                    <svg viewBox="0 0 20 20 " xmlns="http://www.w3.org/2000/svg">
                                        <rect x="5" y="5" width="12" height="12" fill="#3D9FC7" />
                                    </svg>
                              <p>{t("manager_dashboard_customers_old_users")}</p>
                    </div>
                    <div className="chart_types d-flex ">
                                    <svg viewBox="0 0 20 20 " xmlns="http://www.w3.org/2000/svg">
                                        <rect x="5" y="5" width="12" height="12" fill="#3DC7AD" />
                                    </svg>
                              <p>{t("manager_dashboard_customers_new_users")}</p>
                    </div>
          </div>
            </div>

        </div>

    );
}

export default CustomersChart;
