import "./Footer.css";
import studentHatImage from "./illustration-student.png"
// import logo from "../Header/clickLearnNewLogo.png";
import { Link } from "react-router-dom";
import { Modal, Box, Typography, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { servicesFunctions } from "../../../Services/ServicesFunctions";
import { toastsFunctions } from "../../../Services/ToastFunctions";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ServicesManagerDashboard } from "../../../Services/ServicesManagerDashboard";


const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: 'none',
    boxShadow: 24,
    borderRadius: '15px',
    p: 4,
    display: 'flex',
    flexDirection: 'column',
    gap: '35px',
    alignItems: 'center',
    justifyContent: 'center'
  };

function Footer(): JSX.Element {
    const [open, setOpen] = useState(false);
    const handleOpen = () =>{
        setOpen(true);
        setEmailError("");
    } 
    const { t, i18n } = useTranslation();
    const siteImages = useSelector((state: any) => state.siteImagesSlice);


    const handleClose = () => setOpen(false);
    const [emailInput, setEmailInput] = useState<string>("")
    const [emailError, setEmailError] = useState<string>("");

   async function SubscribeNewsletter() {
        if(validateEmail(emailInput)){
            // verify email 
            const sub = await servicesFunctions.Subscribe(emailInput);
            if(!sub){
                let taken_email_text = `${t("toast_functions_email_already_taken")}`
                toastsFunctions.toastError(taken_email_text)
                handleClose();
                return
            } else {

                handleClose();
            let got_the_email_text = `${t("toast_functions_we_got_the_email")}`
                toastsFunctions.toastSuccess(got_the_email_text)
            }
        } else {
            let not_valid_email_text = `${t("toast_functions_not_valid_email")}`
            setEmailError(not_valid_email_text);

        }
    }

    function validateEmail(email: string) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
      }

      
    function handleKeypress(e: any) {
        if (e.keyCode === 13) {
            SubscribeNewsletter();
          }
    }

    const [articles, setArticles] = useState([])
    useEffect(() => {
        ServicesManagerDashboard.getAllArticles().then((res) => {
            setArticles(res)
        })
    }, [])

    function trimToWords(str: string, limit: number) {
        const words = str.split(' ');
        if (words.length > limit) {
          return words.slice(0, limit).join(' ') + '...';
        }
        return str;
      }
      
    return (
        <div className="Footer">
        <footer className="footer" style={{ direction: i18n.language !== "en" ? "rtl" : "ltr" }}>
        <div className="start-learning" >
            <div className="footer-start">
                <div className="texts">
                   <h2 className="section-title">{t("footer_banner_title")}</h2>
                    <h3 className="section-sub-heading">
                        <strong>{t("footer_banner_description_soon")}</strong>
                    <span> {t("footer_banner_description_studying_with")} </span>
                        <strong>{t("footer_banner_description_online")}  </strong> 
                        <span>{t("footer_banner_description_teacher")}</span>
                    </h3>
                </div>
                <a onClick={handleOpen} className="button">
                <span className="label">{t("footer_banner_action_button")}</span>
                </a>

                <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        {t("footer_banner_popup_title")}
                    </Typography>
                    <TextField onKeyDown={handleKeypress} id="outlined-basic" sx={{width: '80%'}} onInput={(e: any) => setEmailInput(e.target.value)} label="Email" variant="outlined" />
                    {emailError && <span style={{ color: "red", position:'absolute', bottom: '85px' }}>{emailError}</span>}
                    <button onClick={() => SubscribeNewsletter()} className="newsletter_modal_btn">{t("footer_banner_popup_send")}</button>
                </Box>
                </Modal>
                <img className={i18n.language !== "en" ? "illustration-left" : "illustration-right"} src={studentHatImage} alt="illustration" width="120" height="94"/>
            </div>
        </div>
        <div className="inner">
            <div className="column is-logo">
                <a href="#" className="main-logo">
                    <Link to="/">
                    <div className="logo">
                        <img src={siteImages?.imageLogo} alt="Click Learn App Logo"/>
                    </div>
                    </Link>
                </a>
            </div>
            <div className={i18n.language !== "en" ? "column is-nav" : "column is-nav text-left"}>
                {i18n.language !== "en" ? "" : ""}
                <div className="column-title">{t("footer_site_navigation")} </div>
                <ul className={i18n.language !== "en" ? "p_right_20" : "p_left_20"}>
                    <li> <Link to="/">{t("menu_home")} </Link></li>
                    <li> <Link to="/dashboard">{t("menu_dashboard")} </Link></li>
                    <li> <Link to="/games">{t("menu_games")}</Link></li>
                    <li> <Link to="/blog">{t("menu_blog")}</Link></li>
                    <li> <Link to="/termsofservice">{t("menu_terms_of_use")} </Link></li>
                    <li> <Link to="/privacypolicy">{t("menu_privacy_policy")} </Link></li>
                </ul>
            </div>
            <div className={i18n.language !== "en" ? "column is-nav" : "column is-nav text-left"}>
                <div className="column-title">{t("menu_contact")}</div>
                <ul className={i18n.language !== "en" ? "p_right_20" : "p_left_20"}>
                    <li><a href="#"><i className="fa fa-envelope-open"></i> clicklearnapp@gmail.com</a></li>
                    <li><a href="#"><i className="fa fa-twitter"></i>@Youtube</a></li>
                    <li><a href="#"><i className="fa fa-linkedin"></i>Linkedin</a></li>
                </ul>
            </div>
            <div className={i18n.language !== "en" ? "column is-nav last_column" : "column is-nav last_column text-left"}>
                <div className="column-title">{t("menu_blog")}</div>
                <ul className={i18n.language !== "en" ? "p_right_20" : "p_left_20"}>

                {articles?.map((article: any) => (
                    i18n.language === "he" ?
                        <li key={article.id}>
                        <Link to={`/blog-post-${article.id}`}>
                            "{trimToWords(article.titleHE, 4)}"
                        </Link>
                        </li>
                    :
                        <li key={article.id}>
                        <Link to={`/blog-post-${article.id}`}>
                           "{trimToWords(article.titleEN, 4)}"
                        </Link>
                        </li>
                    ))}

                    {/* <li> <Link to="/blog-post-2">"{t("blog_post_2_title")}"</Link></li> */}
                    {/* <li> <Link to="/blog-post-3">"{t("blog_post_3_title")}"</Link></li> */}
                </ul>
            </div>
        </div>
    </footer>
        
        
        </div>
    );
}

export default Footer;
