import { useEffect, useState } from "react";
import "./FirstTimeStep3.css";
import { BsFillPlayFill, BsFillStopFill  } from "react-icons/bs";
import { useTranslation } from "react-i18next";

function FirstTimeStep3({ setStep, language, playingMessageId, handleSpeakClicked, choseMaleTeacher, setChoseMaleTeacher, choseFemaleTeacher, setChoseFemaleTeacher}:{ setStep  : (e: number) => void, language: string, playingMessageId: number | null, handleSpeakClicked: (a: any, e: any, b: any, c?:any, d?: any) => void, choseMaleTeacher: boolean, setChoseMaleTeacher: (e: boolean) => void, choseFemaleTeacher: boolean, setChoseFemaleTeacher: (e: boolean) => void }): JSX.Element {
    const [typingComplete, setTypingComplete] = useState(false);
    const [showTeachers, setShowTeachers] = useState(false);
     const [stepForward, setStepForward] = useState(false);
     const { t } = useTranslation();
     let audio: HTMLAudioElement;

    useEffect(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
    });
        const typingAnimation = setTimeout(() => {
            setTypingComplete(true);
        }, 2500); 

        return () =>{
            clearTimeout(typingAnimation);
        } 
    }, []);


    setTimeout(() => {
        setShowTeachers(true)
    }, 2000);

    
    function SpeakMale() {
      let text = "";
      let audioUrl = ""
      if (language === "english") {
        text = "Hey, I'm Mr. Johnson, nice to meet you.";
        audioUrl = "https://res.cloudinary.com/ddubzqehv/video/upload/v1695834745/audio_s4ag1w.mp3"
      } else if (language === "hebrew") {
        text = "היי. אני גל, נעים להכיר.";
        audioUrl = "https://res.cloudinary.com/ddubzqehv/video/upload/v1695834745/audio_s4ag1w.mp3"
      } else if (language === "arabic") {
        text = "مرحبًا، أنا السيد علي، سعيد بلقائك.";
        audioUrl = "https://res.cloudinary.com/ddubzqehv/video/upload/v1695834998/audio_hqti0u.mp3"

      }
    
      handleSpeakClicked(audioUrl,99, text, language, "male");
    };
    
    function SpeakFemale() {
      let text = "";
      let audioUrl = ""
      if (language === "english") {
        text = "Hey, I'm Ms. Wilson, nice to meet you.";
        audioUrl = "https://res.cloudinary.com/ddubzqehv/video/upload/v1695834859/audio_cfcebx.mp3"
      } else if (language === "hebrew") {
        text = "היי, אני ענת, נעים להכיר.";
        audioUrl = "https://res.cloudinary.com/ddubzqehv/video/upload/v1695834576/audio_q4c7go.mp3"
      } else if (language === "arabic") {
        text = "مرحباً، أنا السيد علي، تشرفت بلقائك";
        audioUrl = "https://res.cloudinary.com/ddubzqehv/video/upload/v1695834943/audio_qagfxf.mp3"
      }
    
      handleSpeakClicked(audioUrl, 100, text, language, "female");
    }
    
    function clickedMaleTeacher() {
        setChoseFemaleTeacher(false)
        setChoseMaleTeacher(true)

    }

    function clickedFemaleTeacher() {
        setChoseMaleTeacher(false)
        setChoseFemaleTeacher(true)
        
    }


    function StepForward() {
        setStepForward(true)
    
        setTimeout(() => {
            setStep(4)
        }, 1500);
      }

    return (
      <div className={`FirstTimeStep3 ${stepForward ? "fade_out_all" : ""}`} onClick={() => setShowTeachers(true)}>
        <h1
          className={`typing-effect ${typingComplete ? "typing-complete" : ""}`}
          style={{ color: "white", margin: "100px" }}
        >
          {t("chat_choose_teacher_title")}
        </h1>
        <div
          className={`teachers_container ${
            showTeachers ? "show_teacher" : "none"
          }`}
        >
          <div className={`teacher_card ${choseMaleTeacher ? "chose_teacher" : (choseFemaleTeacher ?  "not_chosed" : "")}`} onClick={clickedMaleTeacher}>
            <div className="icon">
              <img
                src="https://img.freepik.com/free-photo/confident-attractive-caucasian-guy-beige-pullon-smiling-broadly-while-standing-against-gray_176420-44508.jpg?size=626&ext=jpg&ga=GA1.1.2107986295.1695311576&semt=sph"
                alt=""
              />
            </div>
            {language == "english" ? 
            <p className="title">{t("teacherMaleEnglishName")}</p>
          : language == "hebrew" ? 
          <p className="title">{t("teacherMaleHebrewName")}</p>
          : language == "arabic" ? 
          <p className="title">{t("teacherMaleArabicName")}</p>
          : "Something went wrong"}
            <div className="play_button" onClick={SpeakMale}>
              {playingMessageId === 99 ? (
                <BsFillStopFill />
              ) : (
                <BsFillPlayFill />
              )}
              <p>{playingMessageId === 99 ? "Stop" : "Play"}</p>
            </div>
          {language == "english" ? 
            <p className="text">Hey, I'm Mr. Johnson, nice to meet you.</p>
          : language == "hebrew" ? 
          <p className="text">היי. אני גל, נעים להכיר.</p>
        : language == "arabic" ?
        <p className="text">مرحبًا، أنا السيد علي، سعيد بلقائك.</p>
      : "something Went Wrong"}
          </div>
          <div className={`teacher_card ${choseFemaleTeacher ? "chose_teacher" : (choseMaleTeacher ? "not_chosed" : "")}`} onClick={clickedFemaleTeacher}>
            <div className="icon">
              <img
                src="https://img.freepik.com/free-photo/i-like-that-good-job-happy-young-dark-skinned-female-wearing-casual-long-sleeved-t-shirt-making-thumbs-up-sign-smiling-cheerfully-showing-her-support-respect-someone-body-language_273609-1258.jpg?size=626&ext=jpg&ga=GA1.1.2107986295.1695311576&semt=sph"
                alt=""
              />
            </div>
            {language == "english" ? 
            <p className="title">{t("teacherFemaleEnglishName")}</p>
          : language == "hebrew" ? 
          <p className="title">{t("teacherFemaleHebrewName")}</p>
          : language == "arabic" ? 
          <p className="title">{t("teacherFemaleArabicName")}</p>
          : "Something went wrong"}
            {/* <p className="title">Ms. Wilson</p> */}
            <div className="play_button" onClick={SpeakFemale}>
              {playingMessageId === 100 ? (
                <BsFillStopFill />
              ) : (
                <BsFillPlayFill />
              )}
              <p>{playingMessageId === 100 ? "Stop" : "Play"}</p>
            </div>
            {language == "english" ? 
            <p className="text">Hey, I'm Ms. Wilson, nice to meet you.</p>
          : language == "hebrew" ? 
          <p className="text">היי. אני ענת, נעים להכיר.</p>
        : language == "arabic" ?
        <p className="text">مرحباً، أنا الآنسة عائشة، تشرفت بلقائك</p>
      : "something Went Wrong"}
            {/* <p className="text">Hey, I'm Ms. Wilson, nice to meet you.</p> */}
          </div>
        </div>
        <button
        className={`btn_start ${choseMaleTeacher || choseFemaleTeacher ? "reveal" : "none_opacity"}`}
        onClick={StepForward}
      >
        Let's Chat
      </button>
      </div>
    );
}

export default FirstTimeStep3;
