import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./authSlice";
import chosenWebMode from "./lightDarkSlice";
import siteColorsSlice from "./siteColors";
import siteImagesSlice from "./siteImages";

export const store = configureStore({
    reducer: {
       chosenMode: chosenWebMode,
       authSlice: authSlice,
       siteColorsSlice: siteColorsSlice,
       siteImagesSlice: siteImagesSlice 
    }

})