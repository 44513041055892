import { Breadcrumbs, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import "./BlogArticle2.css";
import { useState, useEffect } from "react";
import { servicesFunctions } from "../../../../../../Services/ServicesFunctions";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { ServicesManagerDashboard } from "../../../../../../Services/ServicesManagerDashboard";

function BlogArticle2(): JSX.Element {
  const siteImages = useSelector((state: any) => state.siteImagesSlice);

  const [articleValue, setArticleValue] = useState<any>({});
  const { t, i18n } = useTranslation();
  
    useEffect(() => {
    ServicesManagerDashboard.getArticle(2).then((res) => {
      setArticleValue(res);
    })
    }, [])

    return (
        <div className="BlogArticle2" style={{direction: `${i18n.language === "he" ? "rtl" : "ltr"}`}}>
        <div className="blog_container">
          <div className="breadcrumb">
            <Breadcrumbs aria-label="breadcrumb">
              <Link color="inherit" to="/">
              {t("menu_home")}
              </Link>
              <Link color="inherit" to={"/blog"}>
              {t("menu_blog")}
              </Link>
              <Typography color="text.primary"> {t("menu_current_article")}</Typography>
            </Breadcrumbs>
          </div>
          <div>
            <h1>
            {i18n.language === "he" ? 
            articleValue.titleHE
          : articleValue.titleEN}
          </h1>
          </div>
          <div>
            <h3>
            {i18n.language === "he" ? 
          articleValue.descriptionHE
          : articleValue.descriptionEN}
          </h3>
          </div>
          <div className="img_top_article">
            <img src={siteImages?.imageArticle2Second} alt="" />
          </div>
          <div>
          <p className="article_p"> 
            {i18n.language === "he" ?
            articleValue.bodyHE
          : articleValue.bodyEN}
          </p>
          </div>
        </div>
      </div>
    );
}

export default BlogArticle2;
