import { Chart as ChartJS, ArcElement, Tooltip } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import "./SiteDataChart.css";

ChartJS.register(ArcElement, Tooltip);


  
  const options = {
    responsive: true,
    cutout: 50,
    maintainAspectRatio: false,
    animation: {
      animateScale: true,
      animateRotate: true,
      duration: 1500
    },
    legend: {
      display: false,
    },
    tooltips: {
      enabled: false,
    },
    plugins: {
      datalabels: {
        display: false,
      },
      labels: {
        render: 'percentage',
        precision: 20,
        showZero: true,
        fontSize: 30,
        fontColor: ['#2c405a', '#2c405a', 'white'],
        fontFamily: "PTSansBold",
    }
    },

  };
  
  function SiteDataChart({extentionInfo, siteStatistic, fetchCounter}) {
    // const sum =  (extentionInfo.ThisMonthSumCustomers / extentionInfo.ThisMonthSumSales) * 100
    const webViews = siteStatistic?.webviews ?? 0
const sum2 = 300;
const data = {
    // labels: ['כניסות', 'מכירות', 'רווחיות'],
    datasets: [
      {
        // label: 'כמות',
        data: [webViews, extentionInfo.downloads ,extentionInfo.totalWordsTranslated],
        backgroundColor: ['#008FFB', '#FEB019', '#00E396'],
        borderWidth: 1,
      },
    ],
  };

    return (
      <div className='SiteDataChart'>
        {extentionInfo?.downloads ? 
        <Doughnut data={data} options={options} />
      : 
      <div id={fetchCounter >= 3 ? "" : `loader`}></div>
      }
      </div>
    );
  }
  
  export default SiteDataChart;