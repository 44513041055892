import { createSlice } from '@reduxjs/toolkit';

// Try getting the main color from local storage
const initialMainColor = window.localStorage.getItem("siteColors_main") || '';

const initialState = {
  mainColor: initialMainColor
};

const siteColorsSlice = createSlice({
  name: 'siteColors',
  initialState,
  reducers: {
    setMainColor: (state, action) => {
      const newMainColor = action.payload;
      state.mainColor = newMainColor;
      
      window.localStorage.setItem("siteColors_main", newMainColor);
    },
  }
});

export const { setMainColor } = siteColorsSlice.actions;

export default siteColorsSlice.reducer

