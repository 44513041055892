// // import "./DescWordsToHighlight.css";

// function DescWordsToHighlight({ text, wordsToHighlight }: {text: any, wordsToHighlight: any}): JSX.Element {
//     console.log(text);
    
//     const splittedText = text.split(/(\s+)/); 


//     return (
//   <p>
//       {splittedText?.map((word: string, index: any) => {
//         if (wordsToHighlight?.includes(word.trim())) {
//           return <span key={index} className="highlighted">{word}</span>;
//         } 
//         return word;
//       })}
//     </p>        
//     );
// }

// export default DescWordsToHighlight;
// import "./DescWordsToHighlight.css";

function DescWordsToHighlight({ text, wordsToHighlight }: {text: any, wordsToHighlight: any[]}): JSX.Element {
    const splittedText = text.split(/(\s+)/);
    
    const lowercaseWordsToHighlight = wordsToHighlight.map(word => word.toLowerCase());

    return (
        <p>
            {splittedText?.map((word: string, index: number) => {
                if (lowercaseWordsToHighlight.includes(word.trim().toLowerCase())) {
                    return <span key={index} className="highlighted">{word}</span>;
                } 
                return word;
            })}
        </p>        
    );
}

export default DescWordsToHighlight;
