import "./TopWords.css";
import { useEffect, useState } from "react";
import { ServicesManagerDashboard } from "../../../../../Services/ServicesManagerDashboard";
import { useTranslation } from "react-i18next";

function TopWords(): JSX.Element {
    const [words, setWords] = useState<any>();
    const { t } = useTranslation();
    useEffect(() => {
        ServicesManagerDashboard.getTopWords().then((res) => {
            setWords(res);
        })
    }, [])


    return (
    <div className={`rc_TopWords col-xl-6 col-12 p-4 rc_dashboard_box ${words ? "" : "loading_container"}`}>

        <div className="pb-2"  >
                    <h4 className="rc_component_title">{t("manager_dashboard_top_words")}</h4>
        </div>

        <table>
            <thead>
                <tr>
                    <th>{t("manager_dashboard_top_words_word")}</th>
                    <th>{t("manager_dashboard_top_words_language")}</th>
                    <th>{t("manager_dashboard_top_words_amount_used")}</th>
                    {/* <th>סה"כ הכנסות</th> */}
                </tr>
            </thead>
            <tbody>
            {Array.isArray(words) &&
                words.map((word) => (
                    <tr key={word.englishWord}>
                    <td>{word.englishWord}</td>
                    <td>{word.sourceSymbol?.toUpperCase()}</td>
                    <td>{word.count}</td>
                    </tr>
                ))}
            </tbody>
        </table>

    </div>
    );
}

export default TopWords;
