// import "./TitleWordsToHighlight.css";

function TitleWordsToHighlight({ text, wordsToHighlight }: {text: any, wordsToHighlight: any[]}): JSX.Element {
    const splittedText = text.split(/(\s+)/);

    const lowercaseWordsToHighlight = wordsToHighlight.map(word => word.toLowerCase());

    return (
        <h2>
            {splittedText?.map((word: string, index: number) => {
                if (lowercaseWordsToHighlight.includes(word.trim().toLowerCase())) {
                    return <span key={index} className="highlighted">{word}</span>;
                } 
                return word;
            })}
        </h2>        
    );
}

export default TitleWordsToHighlight;