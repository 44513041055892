import "./SubscribersModal.css";
import * as React from 'react';
import Button from '@mui/joy/Button';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import { ServicesManagerDashboard } from "../../../../../../Services/ServicesManagerDashboard";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai"; 
import { MdDeleteOutline, MdDelete } from "react-icons/md"; 

function SubscribersModal( { editSubscriberModalOpen, setEditSubscriberModalOpen }: { editSubscriberModalOpen: boolean, setEditSubscriberModalOpen: (e: boolean) => void}): JSX.Element {
  const { t, i18n } = useTranslation();
  const [subscribers, setSubscribers] = useState<any[]>([]);
  const [archive, setArchive] = useState<boolean>(true);
  const [filteredSubscribers, setilteredSubscribers] = useState<any[]>([]);
  const [refresh, setRefresh] = useState<boolean>(false)
  // async function NewPodcastValue(newPodcast: any) {
  //   ServicesManagerDashboard.editPodcast(podcastValue.id, newPodcast)
  // }
  useEffect(() => {
    ServicesManagerDashboard.getSubscribers().then((res) => {
      setSubscribers(res)
      if (archive) {
        const filtered = subscribers.filter(sub => sub.seen !== 1);
        setilteredSubscribers(filtered);
      }
      else {
        const filtered = subscribers.filter(sub => sub.seen === 1);
        setilteredSubscribers(filtered);
      }
    })
  }, [refresh])

  useEffect(() => {
    if (archive) {
      const filtered = subscribers.filter(sub => sub.seen !== 1);
      setilteredSubscribers(filtered);
    }
    else {
      const filtered = subscribers.filter(sub => sub.seen === 1);
      setilteredSubscribers(filtered);
    }
  }, [archive, subscribers])


  function deleteSubscriber(id: number) {
    ServicesManagerDashboard.deleteSubscriber(id).then(() => {
      setRefresh(!refresh)
    })
  }
  function seenSubscriber(id: number) {
    ServicesManagerDashboard.seenSubscriber(id).then(() => {
      setRefresh(!refresh)
    })
  }
  function unSeenSubscriber(id: number) {
    ServicesManagerDashboard.unSeenSubscriber(id).then(() => {
      setRefresh(!refresh)
    })
  }
    return (
        <div className="SubscribersModal">
<React.Fragment>
      <Modal open={editSubscriberModalOpen} onClose={() => setEditSubscriberModalOpen(false)}>
        <ModalDialog
          aria-labelledby="basic-modal-dialog-title"
          aria-describedby="basic-modal-dialog-description"
          sx={{ maxWidth: 500, width: "70%", direction:  `${i18n.language === "en" ? "ltr" : "rtl"}`}}
        >
          <Typography id="basic-modal-dialog-title" level="h2">
            {t("manager_dashboard_web_info_subscribers")}
          </Typography>
          <table className="subcribers_desc_archive_line">
            <tr >
              <td>
                <Typography id="subscribers_description">
                  {t("manager_dashboard_web_info_subscribers_desc")}
                </Typography>
              </td>
              <td>
                <button className={`${archive ? "archive" : "unarchive"} btnArchive`} onClick={() => setArchive(!archive)}>
                  {archive ? 
                    t("manager_dashboard_web_info_subscribers_archive")
                    : 
                    t("manager_dashboard_web_info_subscribers_unarchive")
                  }
                </button>
                </td>
            </tr>
          </table>

                <table className="subscribers_modal_line">
                {filteredSubscribers?.map((sub, index) => (
                  <tr key={index} >
                    <td>{index + 1}</td>
                    <td>{sub.email}</td>
                    <td className="seen-container">
                      {archive ?
                      <div onClick={() => seenSubscriber(sub.id)}>
                        <span className="seen_outline"><AiFillEye/></span>
                        <span className="seen"><AiFillEyeInvisible/></span>
                      </div>
                      : 
                      <div onClick={() => unSeenSubscriber(sub.id)}>
                        <span className="seen"><AiFillEye/></span>
                        <span className="seen_outline"><AiFillEyeInvisible/></span>
                      </div>
                    }
                      </td>
                    {/* <td className="delete-container" onClick={delete}> */}
                    <td className="delete-container" onClick={() => deleteSubscriber(sub.id)}>
                      <span className="delete_outline"><MdDeleteOutline/></span>
                      <span className="delete"><MdDelete/></span>
                    </td>
                  </tr>

                ))}
                </table>
              {/* </div> */}
            {/* </Stack> */}
        </ModalDialog>
      </Modal>
    </React.Fragment>
        </div>
    );
}

export default SubscribersModal;

