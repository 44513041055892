import "./Blog.css";
import { useNavigate } from "react-router-dom";
import PodcastsSection from "./PodcastsSection/PodcastsSection";
import AdvantagesSection from "./AdvantagesSection/AdvantagesSection";
import { useState, useEffect } from "react";
import { servicesFunctions } from "../../../../Services/ServicesFunctions";
import { useSelector } from "react-redux";
import { ServicesManagerDashboard } from "../../../../Services/ServicesManagerDashboard";
import { useTranslation } from "react-i18next";

interface blogImages{
    imageArticle1Main: string;
    imageArticle2Main: string;
    imageArticle3Main: string;
    imageMainBlog: string;
}
function Blog(): JSX.Element {
    const navigate = useNavigate()
    const siteImages = useSelector((state: any) => state.siteImagesSlice);
    const [articles, setArticles] = useState<any>([]);
    const { i18n } = useTranslation();

    useEffect(() => {
        ServicesManagerDashboard.getAllArticles().then((res) => {
            setArticles(res)
        console.log(res);
        
        })
    }, [])

    return (
        <div className="Blog">
			<div className="blog_main_img_container">
                <img className="main_image"src={siteImages?.imageMainBlog} alt="" />
            </div>
			<div className="blog_articles_container">
                <div className="article" onClick={() => navigate("/blog-post-3")}>
                <img className="image_article" src={siteImages?.imageArticle1Main} alt="" />
                {/* <p className="article_title">"למידת אנגלית באינטרנט: הפתרון הנוח"</p> */}
                <p className="article_title">"{i18n.language == "he" ? articles[2]?.titleHE : i18n.language == "ar" ? articles[2]?.titleAR : articles[2]?.titleEN}"</p>
                {/* <p className="article_author">היתרונות הרבים של למידת אנגלית באמצעות האתר שלנו, כולל לוח זמנים גמיש, מסלולי למידה מותאמים אישית, וגישה לדוברי אנגלית שוטפים.</p> */}
                </div>
                <div className="article" onClick={() => navigate("/blog-post-2")}>
                <img className="image_article" src={siteImages?.imageArticle2Main} alt="" />
                {/* <p className="article_title">"מעבר מעברית לאנגלית: טיפים וטכניקות"</p> */}
                <p className="article_title">"{i18n.language == "he" ? articles[1]?.titleHE : i18n.language == "ar" ? articles[1]?.titleAR : articles[1]?.titleEN}"</p>
                {/* <p className="article_author">אסטרטגיות מועילות שיעזרו לדוברי עברית להתמודד עם אתגרים נפוצים בלמידת האנגלית, כמו תרגול מילים, דקדוק וביטוי.</p> */}
                </div>
                <div className="article" onClick={() => navigate("/blog-post-1")}>
                <img className="image_article" src={siteImages?.imageArticle3Main} alt="" />
                {/* <p className="article_title">"היתרונות של למידת אנגלית וההזדמנויות"</p> */}
                <p className="article_title">"{i18n.language == "he" ? articles[0]?.titleHE : i18n.language == "ar" ? articles[0]?.titleAR : articles[0]?.titleEN}"</p>
                {/* <p className="article_author">היתרונות הרבים של שליטה בשפה אנגלית ועברית ברמה גבוהה, בין אם זה בתחום העבודה, קשרים בינלאומיים או טיולים.</p> */}
                </div>

            </div>

                <PodcastsSection/>
                <AdvantagesSection/>
        </div>
    );
}

export default Blog;
