import { useEffect, useState } from "react";
import "./FirstTimeStep2.css";
import { useTranslation } from "react-i18next";

function FirstTimeStep2({ setStep, language, setLanguage }: { setStep  : (e: number) => void, language: string, setLanguage : (e : string) => void }): JSX.Element {
const [stepForward, setStepForward] = useState(false);
const [chooseLanguage, setChooseLanguage] = useState(false);
const [typingComplete, setTypingComplete] = useState(false);
const { t } =  useTranslation();
useEffect(() => {
	window.scrollTo({
        top: 0,
        behavior: "smooth"
    });
	const typingAnimation = setTimeout(() => {
		setTypingComplete(true);
	}, 2500); // Adjust the duration as needed

	return () =>{
		clearTimeout(typingAnimation);
	} 
}, []);

    function StepForward() {
		if(!language) return
        setStepForward(true)
    
        setTimeout(() => {
            setStep(3)
        }, 1500);
      }

	  function choseLanguage(event: React.ChangeEvent<HTMLInputElement>) {
		const name = event.target.name;
		setChooseLanguage(true);
		console.log((name));
		setLanguage(name)
	  }

    return (
      <div className={`FirstTimeStep2 ${stepForward ? "fade_out_all" : ""}`}  onClick={() => setTypingComplete(true)}>

		<h1
          className={`typing-effect ${typingComplete ? "typing-complete" : ""}`}
        >
		  {t("chat_choose_language")}
        </h1>

    <div className={`radio-inputs  ${typingComplete ? "show_languages" : "none"}`}>
		<label>
			{/* <input className="radio-input" type="radio" name="arabic" onChange={() => {setChooseHebrew(false); setChooseArabic(true); setChooseEnglish(false); }}/> */}
			<input className="radio-input" type="radio" name="arabic" onChange={choseLanguage}/>
				<span className="radio-tile">
			<img src="https://img.freepik.com/free-vector/sudan-flag-pattern-vector_53876-162415.jpg?w=1480&t=st=1695453551~exp=1695454151~hmac=30ac418de92560e844e3abdc174393b2a4d490f53f65fdc49d2bf0d927983dbe" alt="" />
					<span className="radio-icon">
					</span>
				</span>
					<span className="radio-label">عربي</span>
		</label>
		<label>
			<input checked={false} className="radio-input" type="radio" name="hebrew" onChange={choseLanguage}/>
			<span className="radio-tile">
			<img src="https://img.freepik.com/free-vector/illustration-israel-flag_53876-27122.jpg?w=1480&t=st=1695419639~exp=1695420239~hmac=df8b279beee40380168dac3a25e30569e0dc3261c39b061316d7b1e7ba08b7aa" alt="" />
				<span className="radio-icon">
				</span>
			</span>
				<span className="radio-label">עברית</span>
		</label>
		<label>
			<input className="radio-input" type="radio" name="english" onChange={choseLanguage}/>
			<span className="radio-tile">
			<img src="https://img.freepik.com/free-vector/illustration-usa-flag_53876-18165.jpg?size=626&ext=jpg&ga=GA1.1.2107986295.1695311576&semt=ais" alt="" />
				<span className="radio-icon">
				</span>
			</span>
				<span className="radio-label">English</span>
		</label>
</div>

		<button
        className={`btn_start ${chooseLanguage ? "reveal" : "none_opacity"}`}
        onClick={StepForward}
      >
		{t("chat_lets_chat")}
      </button>

      </div>
    );
}

export default FirstTimeStep2;
