import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Layout from './Components/Layout/Layout';
import { BrowserRouter } from 'react-router-dom';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { store } from './App/store';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ScrollToTop from "./helpers/ScrollToTop";
import ReactGA from "react-ga4";
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { servicesFunctions } from './Services/ServicesFunctions';
import { SiteColors, siteImagesModel } from './Models/siteModel';
import { setMainColor } from './App/siteColors';
import { setSiteImages } from './App/siteImages';

function App() {
  const webMode = useSelector((state: any) => state.chosenMode.toggle);
  
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState(window.localStorage.getItem("language") ?? "he");
  const currentMainColor = useSelector((state: any) => state.siteColorsSlice.mainColor);
  const siteImages = useSelector((state: any) => state.siteImagesSlice);
  const [siteColors, setSiteColors] = useState<SiteColors>();
  const dispatch = useDispatch();

  useEffect(() => {
      i18n.changeLanguage(language);

      if (language === 'en') {
          document.documentElement.setAttribute('dir', 'rtl');
        } else {
          document.documentElement.setAttribute('dir', 'ltr'); 
        }
    }, [language]);
    



    useEffect(() => {
      servicesFunctions.getSiteColors().then((res: SiteColors) => {
        setSiteColors(res);
      });
      
      servicesFunctions.getSiteImages().then((res: siteImagesModel) => {
        dispatch(setSiteImages(res))
      });
    }, [currentMainColor, siteImages]);
  
    useEffect(() => {
        if (siteColors?.mainColor) {
          document.documentElement.style.setProperty('--color-light-green', siteColors.mainColor);
          dispatch(setMainColor(siteColors?.mainColor));
        }
      }, [siteColors]);
    
      // useEffect(() => {
      //   let visited = window.localStorage.getItem("visitedClickLearn")
      //   if(visited){
      //     if(visited == "true"){
      //       // sent return customer
      //       window.localStorage.setItem("visitedClickLearn", "2nd")
      //     }
      //   } else if(visited == "2nd"){
      //     return 
      //   } else {
      //     // sent new customer
      //     window.localStorage.setItem("visitedClickLearn", "true")
      //   }
      // }, [])
    
    
useEffect(() => {
  const currentTime = new Date().getTime();
  const halfDayMillis = 12 * 60 * 60 * 1000; // Half day in milliseconds

  const visited = window.localStorage.getItem("visitedClickLearn");
  const lastVisitTimeStr = servicesFunctions.getCookie("lastVisitTimeClickLearn");

  if (lastVisitTimeStr) {
    const lastVisitTime = parseInt(lastVisitTimeStr, 10);
    const timePassed = currentTime - lastVisitTime;

    if (visited === "true") {
      if (timePassed > halfDayMillis) {
        // Rule 3: send return customer + web view
        window.localStorage.setItem("visitedClickLearn", "2nd");
        servicesFunctions.setCookie("lastVisitTimeClickLearn", `${currentTime}`, 12);
        servicesFunctions.returnCustomer()
        // TODO: Send "return customer" and "web view"
      } else {
        // Rule 1: if time hasn't passed 12 hours, do not send anything
        return;
      }
    } else if (visited === "2nd") {
      if (timePassed > halfDayMillis) {
        // Rule 4: send web view
        servicesFunctions.setCookie("lastVisitTimeClickLearn", `${currentTime}`, 12);
        servicesFunctions.webView()
        // TODO: Send "web view"
      } else {
        // Rule 5: do not send anything
        return;
      }
    }
  } else {
    // Rule 2: send new customer + web view
    window.localStorage.setItem("visitedClickLearn", "true");
    servicesFunctions.setCookie("lastVisitTimeClickLearn", `${currentTime}`, 12);
    servicesFunctions.newCustomer()
    // TODO: Send "new customer" and "web view"
  }
}, []);
      
      
      
  return (
    <BrowserRouter>
      <Layout />
      <ScrollToTop />

      <ToastContainer theme={webMode ? 'light' : 'dark'} />

    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

ReactGA.initialize("G-T88M5S5TSF");

root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

const SendAnalytics = ()=> {
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
  });
}

reportWebVitals(SendAnalytics);